import axios, { AxiosResponse } from "axios";
import { UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import { callApiWithTryCatch } from "app/utils/apiUntils";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/place-categories`;
const GET_USERS_LIKE_POST_URL = `${API_URL}/articles/user-like`;
const GET_USERS_COMMENT_POST_URL = `${API_URL}/articles/user-comment`;
const getCategory = (query: any): Promise<UsersQueryResponse> => {
  return (
    axios
      .get(`${GET_USERS_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.list;
        const pagination = d.data.meta;
        const articleType = d.data.data.articleType;

        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(
              pagination?.page,
              pagination?.total,
              pagination?.limit
            ),
            total: pagination?.total,
          },
          articleType: articleType,
        };
      })
  );
};

const edit_category = (id: any, body: any) => {
  const formData = new FormData();
  if (body?.name) formData.append("name", JSON.stringify(body.name));
  if (body.parentId) {
    formData.append("parentId", body.parentId);
  }
  if (body?.key) formData.append("key", body.key);
  if (body.icon) {
    formData.append("icon", body.icon);
  }
  if (body?.isActive) formData.append("isActive", body.isActive);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-ms-blob-type": "BlockBlob",
    },
  };

  return axios.put(`${GET_USERS_URL}/${id}`, formData, config);
};

const delete_product = (id: any) => {
  return axios.delete(`${GET_USERS_URL}/${id}`);
};

const create_category = (body: any) => {
  const formData = new FormData();
  formData.append("name", JSON.stringify(body.name));
  if (body.parentId) {
    formData.append("parentId", body.parentId);
  }
  formData.append("key", body.key);
  if (body.icon) {
    formData.append("icon", body.icon);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-ms-blob-type": "BlockBlob",
    },
  };

  return axios.post(`${GET_USERS_URL}`, formData, config);
};
const revert_post = (id: any) => {
  return axios.put(`${GET_USERS_URL}/restore/${id}`);
};

export const getPaginationUserLikePost = async (query: any, id: any) => {
  const newquery = applyDefaultQueryConditions(query);

  // if (!newquery.order_by) {
  //   newquery.sort_by = "view";
  //   newquery.order_by = "DESC";
  // }
  const res = await callApiWithTryCatch(
    async () =>
      await axios.get(`${GET_USERS_LIKE_POST_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

const getUsersLikePost = (query: any) => {
  const newquery = { ...query };
  return (
    axios
      .get(`${GET_USERS_LIKE_POST_URL}`, {
        params: newquery,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.data.list;
        const pagination = d.data.data.pagination;
        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(
              pagination?.page,
              pagination?.total,
              pagination?.limit
            ),
            total: pagination?.total,
          },
        };
      })
  );
};

const getUsersCommentPost = (query: any) => {
  return (
    axios
      .get(`${GET_USERS_COMMENT_POST_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.data.list;
        const pagination = d.data.data.pagination;
        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(
              pagination?.page,
              pagination?.total,
              pagination?.limit
            ),
            total: pagination?.total,
          },
        };
      })
  );
};

export const getPaginationCategory = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);

  const res = await axios.get(`${GET_USERS_URL}`, { params: newquery });
  const { data, total } = res.data;
  const pagination = {
    page: newquery.page,
    total: total,
    limit: newquery.limit,
  };
  return {
    data: data as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
  };
};

export {
  getCategory,
  edit_category,
  delete_product,
  create_category,
  getUsersCommentPost,
  revert_post,
  getUsersLikePost,
};
