// @ts-nocheck
import { Tag } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import moment from "moment";
import Fancybox from "app/_components/Fancybox";
import AsyncImage from "app/_components/AsyncImage";

const EXTRA_TYPE = [
  {
    value: "charge",
    label: "CHARGE",
    color: "lime",
  },
  {
    value: "revenue",
    label: "REVENUE",
    color: "gold",
  },

  {
    value: "revenue_chat",
    label: "REVENUE_CHAT",
    color: "green",
  },
  {
    value: "mission",
    label: "MISSION",
    color: "cyan",
  },

  {
    value: "commission",
    label: "COMMISSION",
    color: "blue",
  },

  {
    value: "refund_level",
    label: "REFUND_LEVEL",
    color: "purple",
  },
  {
    value: "refund",
    label: "REFUND",
    color: "geekblue",
  },
  {
    value: "purchase",
    label: "PURCHASE",
    color: "magenta",
  },

  {
    value: "withdrawal",
    label: "WITHDRAWAL",
    color: "red",
  },

  {
    value: "purchase_chat",
    label: "PURCHASE_CHAT",
    color: "volcano",
  },

  {
    value: "purchase_explore",
    label: "PURCHASE_EXPLORE",
    color: "orange",
  },

  {
    value: "register",
    label: "REGISTER",
    color: "yellow",
  },
];

const usersColumns: any = (hasPermissionUpdate: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        useQueryRequest={useQueryRequest}
        className="min-w-50px"
      />
    ),
    id: "id",
    accessor: "id",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row?.user} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="OPERATOR"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "operator",
    accessor: "user.operator.username",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CONTENT-TYPE"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "content-type",
    Cell: ({ ...props }) => {
      const intl = useIntl();

      const row = props?.row?.original;
      const extraRef = row?.extraRef;
      let value = "";
      if (extraRef == "UserProfileChatterEntity") value = "CHAT";
      if (extraRef == "AlbumFileEntity") value = "SALE-IMAGE";
      if (extraRef == "AlbumEntity") value = "SALE-ALBUM";

      return (
        value &&
        intl.formatMessage({
          id: value,
        })
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CONTENT"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "content",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const extraRef = row?.extraRef;

      if (extraRef == "UserProfileChatterEntity")
        return row?.extraData?.content;
      if (extraRef == "AlbumFileEntity" || extraRef == "AlbumEntity") {
        return (
          row?.extraData?.length > 0 && (
            <Fancybox
              options={{
                Carousel: {
                  infinite: true,
                },
              }}
              key={Math.random()}
            >
              <div style={{ display: "inline-block" }}>
                <div className="d-flex gap-2 flex-wrap align-items-center">
                  {row?.extraData?.map((i: any) => {
                    return <AsyncImage i={i} />;
                  })}
                </div>
              </div>
            </Fancybox>
          )
        );
      }
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NOW"
        className="min-w-125px"
        isNoSort={true}
        isCenter={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    isCenterRow: true,
    id: "amount",
    accessor: "amount",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const value =
        row?.balanceAfter > row?.balanceBefore ? (
          <div className="text-primary">
            + {new Intl.NumberFormat().format(row?.amount) || "0"}
          </div>
        ) : (
          <div className="text-danger">
            - {new Intl.NumberFormat().format(row?.amount) || "0"}
          </div>
        );
      return <>{value}</>;
    },
  },

  /* {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="EXTRA-TYPE"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "type",
    Cell: ({ ...props }) => {
      const intl = useIntl();

      const row = props?.row?.original;

      return (
        <div className="d-flex flex-wrap">
          <Tag
            style={{ marginTop: 10 }}
            bordered={false}
            color={
              EXTRA_TYPE.find((i) => i.value === row?.type)?.color || "green"
            }
          >
            {intl.formatMessage({
              id:
                EXTRA_TYPE.find((i) => i.value === row?.type)?.label ||
                row?.type,
            })}
          </Tag>
        </div>
      );
    },
  }, */
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TRANSACTION-DATE"
        className="min-w-125px"
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.createdAt;

      let day_kr = moment.utc(date).tz("Asia/Seoul").format("YYYY.MM.DD");
      let time_kr = moment.utc(date).tz("Asia/Seoul").format("HH:mm:ss");

      let day_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("YYYY.MM.DD");
      let time_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("HH:mm:ss");
      return (
        <div className="d-flex flex-column">
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              KR: {day_kr}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_kr}
            </div>
          </div>
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              VN: {day_vn}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_vn}
            </div>
          </div>
        </div>
      );
    },
  },
];

export { usersColumns };
