/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import { notification } from "antd";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { create_category, edit_category } from "../../core/_requests";
import InputComponent from "./InputComponent";
import { useState } from "react";
import { toAbsoluteUrlENV } from "_metronic/helpers";

const Step1 = ({
  data,
  updateData,
  refetch,
  handleClose,
  info,
  parentId = null,
}: any) => {
  const isEdit = !!info;
  const intl = useIntl();
  const lang = useLang();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const createEditProductSchema = Yup.object().shape({
    key: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
    name_ko: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    name_en: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    name_vi: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
  });

  const initialValues = {
    icon: data?.icon || "",
    key: data?.key || "",

    name_ko: data?.name?.ko || "",
    name_en: data?.name?.en || "",
    name_vi: data?.name?.vi || "",
    parentId: data?.parentId || parentId || "",
  };
  const formik: any = useFormik({
    enableReinitialize: isEdit,
    initialValues,
    validationSchema: createEditProductSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const fortmat_data = {
          parentId: values?.parentId || parentId || null,
          // createdAt: "2024-01-15T01:08:31.708Z",
          // updatedAt: "2024-01-15T07:30:07.000Z",
          key: values?.key,
          name: {
            en: values?.name_en,
            ko: values?.name_ko,
            vi: values?.name_vi,
          },
          icon: file,
        };
        let data = null;
        setIsLoading(true);
        if (info) {
          data = await edit_category(info.id, fortmat_data);
        } else {
          data = await create_category(fortmat_data);
        }
        if (data?.status === 201 || data?.status === 200) {
          notification.success({
            message: info?.id
              ? intl.formatMessage({
                  id: "UPDATE-SUCCESS",
                })
              : intl.formatMessage({
                  id: "CREATE-SUCCESS",
                }),
          });
        } else {
          notification.error({
            message: data?.data || "error",
            duration: 3,
          });
        }
        console.log(data);
        setIsLoading(false);
        handleClose();
        refetch();
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    },
  });
  function handleFileChange() {
    const fileInput: any = document.getElementById(`message-files`);
    if (fileInput?.files?.length > 0) {
      setFile(fileInput.files[0]);
    }
  }
  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm  min-w-400px"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          <div
            className="row"
            style={{ display: "flex", gap: 10, flexWrap: "wrap" }}
          >
            <InputComponent
              formik={formik}
              label={intl.formatMessage({ id: "KOREAN_NAME" })}
              fieldKey="name_ko"
            />

            {formik.touched.platform && formik.errors.platform && (
              <div className="text-danger mt-2">{formik?.errors?.platform}</div>
            )}
          </div>
          <div
            className="row"
            style={{ display: "flex", gap: 10, flexWrap: "wrap" }}
          >
            <InputComponent
              formik={formik}
              label={intl.formatMessage({ id: "EN_NAME" })}
              fieldKey="name_en"
            />

            {formik.touched.platform && formik.errors.platform && (
              <div className="text-danger mt-2">{formik?.errors?.platform}</div>
            )}
          </div>
          <div
            className="row"
            style={{ display: "flex", gap: 10, flexWrap: "wrap" }}
          >
            <InputComponent
              formik={formik}
              label={intl.formatMessage({ id: "VI_NAME" })}
              fieldKey="name_vi"
            />

            {formik.touched.platform && formik.errors.platform && (
              <div className="text-danger mt-2">{formik?.errors?.platform}</div>
            )}
          </div>
          <div className="row" style={{ display: "flex", gap: 10 }}>
            <InputComponent
              formik={formik}
              label={intl.formatMessage({ id: "KEY" })}
              fieldKey="key"
            />
          </div>
          <div className="row" style={{ display: "flex", gap: 10 }}>
            {(info?.icon || file) && (
              <img
                src={
                  file
                    ? URL.createObjectURL(file as any)
                    : toAbsoluteUrlENV(info?.icon)
                }
                alt="icom"
                style={{
                  width: 50,
                  height: "100%",
                  objectFit: "cover",
                  margin: "auto 0",
                }}
              />
            )}
            <input id="message-files" type="file" onChange={handleFileChange} />
          </div>
          <div className="row mt-8" style={{ display: "flex", gap: 10 }}>
            {((data?.id && data?.parentId) || parentId) && (
              <InputComponent
                disabled
                formik={formik}
                label={intl.formatMessage({ id: "PARENT-CATEGORY" })}
                fieldKey="parentId"
                required={false}
                format="number"
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-stack justify-content-end">
        <button
          disabled={formik.isSubmitting || !formik.isValid || isLoading}
          className="btn btn-lg btn-primary"
          //data-kt-stepper-action="next"
          //onClick={handleSubmit}
          type="submit"
        >
          {!isLoading && intl.formatMessage({ id: "SAVE" })}
          {isLoading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>{" "}
    </form>
  );
};

export { Step1 };
