import { KTIcon, PERMISSIONS_MAP, hasPermission } from "_metronic/helpers";
import { DatePicker } from "antd";
import { useAuth } from "app/_modules/auth";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CreateAppModal } from "../../components/edit-agency";

const { RangePicker } = DatePicker;

type Props = {
  show: boolean;
  handleClose: () => void;
  triggerListNotify: () => void;
};

const UsersListToolbar = ({
  show,
  handleClose,
  info,
  triggerListNotify,
  handleRefetch,
}: any) => {
  const intl = useIntl();
  const { allPermission, myPermission } = useAuth();

  const hasPermissionCreateEvent = hasPermission(
    PERMISSIONS_MAP.CREATE_NOTICE,
    myPermission,
    allPermission
  );

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  return (
    <div
      className="d-flex justify-content-end mb-4 p-8 pb-0"
      data-kt-user-table-toolbar="base"
    >
      {/* <UsersListFilter /> */}

      <div className="card-toolbar"></div>

      {/* begin::Add user */}
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setShowCreateAppModal(true)}
      >
        <KTIcon iconName="plus" className="fs-2" />
        {intl.formatMessage({ id: "CREATE-AGENT" })}
      </button>

      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        triggerListNotify={handleRefetch}
      />
      {/* end::Add user */}
    </div>
  );
};

export { UsersListToolbar };
