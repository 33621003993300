import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
// import { createNewNotification } from "../_requests";
import { Step1 } from "./steps/Step1";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";

type Props = {
  show: boolean;
  handleClose: () => void;
  triggerListNotify: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({
  show,
  handleClose,
  info,
  triggerListNotify,
}: any) => {
  const intl = useIntl();
  const [isViewReferral, setIsViewReferral] = useState<boolean>();
  const [data, setData] = useState<any>();
  const [hasError, setHasError] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const { refetch } = useQueryResponse();
  const updateData = (fieldsToUpdate: any) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  useEffect(() => setData(info), [info]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px role-info-modal"
      show={show}
      onHide={handleClose}
      backdrop={true}
      onBackdropClick={handleClose}
    >
      <div className="modal-header">
        <h2>
          {info
            ? intl.formatMessage({ id: "EDIT" })
            : intl.formatMessage({ id: "CREATE" })}
        </h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          <div className="px-lg-5 w-full">
            <Step1 data={data} info={info} handleClose={handleClose} />
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
