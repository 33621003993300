/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormControl } from "@mui/material";
import { useLang } from "_metronic/i18n/Metronici18n";
import { ColorPicker, DatePicker, Select, notification } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputComponent from "./InputComponent";
import { create_product, edit_product } from "../../core/_requests";
import { useEffect, useState } from "react";
import {
  getAllActiveFakeFemaleUsers,
  getAllFakeUserByGender,
} from "../../../users-list-fake/core/_requests";
import clsx from "clsx";
import { toAbsoluteUrlENV } from "_metronic/helpers";
import { GENDER } from "app/constants";

const Step1 = ({ refetch, handleClose, info }: any) => {
  const [randomNumber, setRandomNumber] = useState(20);
  const isEdit = !!info;
  const intl = useIntl();
  const lang = useLang();

  const data = info;

  const [allUsers, setAllUsers] = useState<any>([]);

  const createEditProductSchema = Yup.object().shape({
    fullName: Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),
    username: Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),

    password: isEdit
      ? Yup.string().nullable().notRequired()
      : Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),

    userGender: Yup.string().nullable().notRequired(),
    users: Yup.array().required(intl.formatMessage({ id: "REQUIRED" })),
  });

  const initialValues = {
    fullName: data?.fullName || "",
    username: data?.username || "",
    password: data?.password || "",
    userGender: data?.userGender || "",
    users: data?.users?.map((i: any) => i?.id?.toString()) || [],
  };

  const formik: any = useFormik({
    enableReinitialize: isEdit,
    initialValues,
    validationSchema: createEditProductSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const fortmat_data = {
          fullName: values?.fullName,
          password: values?.password,
          username: values?.username,
          ...(values?.userGender && { userGender: values?.userGender }),
          users: values?.users?.map((i: any) => Number(i)),
        };
        let res;
        if (info) {
          delete fortmat_data.password;
          res = await edit_product(info.id, fortmat_data);
        } else {
          res = await create_product(fortmat_data);
        }
        if (res?.status == 200 || res?.status == 201) {
          notification.success({
            message: info?.id
              ? intl.formatMessage({
                  id: "UPDATE-SUCCESS",
                })
              : intl.formatMessage({
                  id: "CREATE-SUCCESS",
                }),
          });
          handleClose();
          refetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    getFakeUserByGender();
  }, [formik.values.userGender]);

  async function getFakeUserByGender() {
    const res = await getAllFakeUserByGender(formik.values.userGender);
    if (res?.status == 200) {
      const data =
        info?.users?.length > 0
          ? [...info?.users, ...res?.data?.data]
          : res?.data?.data;
      const formatData = data?.map((d: any) => {
        return {
          value: d?.id,
          label: d?.nickName,
          renderLabel: (
            <>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                  <div className={clsx("symbol-label fs-3")}>
                    <img
                      alt="user"
                      src={
                        d?.pictures?.[0]
                          ? toAbsoluteUrlENV(d?.pictures?.[0])
                          : "/media/avatars/blank.png"
                      }
                      className="w-100"
                    />
                  </div>
                </div>
                {d.nickName}
              </div>
            </>
          ),
        };
      });

      setAllUsers(formatData);
    }
  }

  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm min-w-400px"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          <div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "FULLNAME" })}
                fieldKey="fullName"
              />
            </div>
          </div>
          {/* KO */}
          <div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "USERNAME" })}
                fieldKey="username"
                disabled={isEdit}
              />
            </div>
          </div>

          {/* EN */}
          {!isEdit && (
            <div>
              <div className="row">
                <InputComponent
                  formik={formik}
                  label={intl.formatMessage({
                    id: "PASSWORD",
                  })}
                  fieldKey="password"
                  inputType="password"
                  isRequired={isEdit ? false : true}
                />
              </div>
            </div>
          )}

          {/* VI */}

          <div className="fv-row mb-10">
            <label className="fs-5 fw-semibold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "USER" })}
              </span>
            </label>

            <div className="d-flex justify-content-between w-full">
              <div className="d-flex align-items-center gap-2">
                <span>{intl.formatMessage({ id: "GENDER" })}</span>
                <div style={{ width: 100, marginBottom: 10 }}>
                  <Select
                    onChange={(value) => {
                      formik.setFieldValue("userGender", value);
                      formik.setFieldValue("users", []);
                    }}
                    value={formik.values?.userGender}
                  >
                    {GENDER?.map((option: any) => (
                      <Select.Option key={option.value}>
                        {intl.formatMessage({ id: option.label.toString() })}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="number"
                  style={{ width: 100 }}
                  value={randomNumber}
                  onChange={(e: any) => setRandomNumber(e?.target?.value)}
                />{" "}
                <div
                  className="cursor-pointer"
                  style={{ color: "rgba(0,158,247,1)", textAlign: "right" }}
                  onClick={() => {
                    const shuffledArray = [...allUsers].sort(
                      (a, b) => 0.5 - Math.random()
                    );

                    if (randomNumber <= shuffledArray?.length) {
                      const randomIds = shuffledArray
                        .slice(0, randomNumber)
                        .map((i: any) => i?.value?.toString());

                      formik.setFieldValue("users", randomIds);
                    } else {
                      const randomIds = shuffledArray.map((i: any) =>
                        i?.value?.toString()
                      );
                      formik.setFieldValue("users", randomIds);
                    }
                  }}
                >
                  {intl.formatMessage({ id: "RANDOM" })}
                </div>
              </div>
            </div>

            <Select
              mode="multiple"
              showArrow
              placeholder={intl.formatMessage({
                id: "SELECT-FAKE-USERS",
              })}
              value={formik.values?.users}
              filterOption={(inputValue: any, option: any) => {
                const thisItem = allUsers.find(
                  (i: any) => i?.value == option?.value
                );
                return thisItem?.label
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());

                // return option.children
                //   ?.toString()
                //   ?.concat(option?.value?.toString())
                //   ?.toLowerCase()
                //   ?.includes(inputValue?.toLowerCase());
              }}
              onChange={(value) => {
                console.log(value);
                formik.setFieldValue("users", value);
              }}
              className="w-100 operator-female-select"
            >
              {allUsers?.map((option: any) => (
                <Select.Option key={option.value}>
                  {option.renderLabel}
                </Select.Option>
              ))}
            </Select>
            {formik.errors.users && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  {formik.errors.users}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-stack justify-content-end">
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          disabled={/* formik.isSubmitting || */ !formik.isValid}
        >
          {intl.formatMessage({ id: "SAVE" })}
        </button>
      </div>{" "}
    </form>
  );
};

export { Step1 };
