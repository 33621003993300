import axios, { AxiosResponse } from "axios";
import { ListAgencyResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";
const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = "http://192.168.103.20:9113/v1.0";
const GET_LIST_AGENCY_URL = `${API_URL}/agencies/withdrawal`;

export const getPanigationAgency = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_LIST_AGENCY_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { data, pagination } = res;
  return {
    data: data as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(
        newquery?.page,
        pagination.total || 0,
        newquery?.limit
      ),
      total: pagination.total,
      totalReferred: res.totalReferred,
    },
  };
};

const createAgency = (params?: any): Promise<ListAgencyResponse> => {
  return axios
    .post(`${GET_LIST_AGENCY_URL}`, params)
    .then((d: AxiosResponse<any>) => {
      return d?.data;
    });
};
const editAgency = (params?: any) => {
  return axios
    .put(`${GET_LIST_AGENCY_URL}/${params.id}`, params)
    .then((d: AxiosResponse<any>) => {
      return d?.data;
    });
};

const deleteAgency = (id?: any): Promise<ListAgencyResponse> => {
  return axios
    .delete(`${GET_LIST_AGENCY_URL}/${id}`)
    .then((d: AxiosResponse<any>) => {
      return d?.data;
    });
};

const getAllAgency = () => {
  return axios.get(`${GET_LIST_AGENCY_URL}`, {
    params: {
      page: 1,
      limit: 10000,
      sort_by: "createdAt",
      order_by: "DESC",
    },
  });
};

export const getStatisticAgencyRevenue = async (query: any) => {
  const res = await axios.get(`${GET_LIST_AGENCY_URL}/summary`, {
    params: { ...query },
  });

  return res.data;
};

export { createAgency, editAgency, deleteAgency, getAllAgency };
