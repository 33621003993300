import NumberDelay from "./NumberDelay";

const TableSummary = ({ cardList }: any) => {
  return (
    <div className="card-header border-bottom-0 pt-6">
      <div className="d-flex flex-wrap">
        {cardList?.map((i: any, index: number) => (
          <div
            style={{ minHeight: 62 }}
            key={index}
            className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3"
          >
            <div className="fs-6 text-gray-800 fw-bolder">
              {typeof i?.value == "number" ? (
                <NumberDelay number={i.value || 0} />
              ) : (
                i?.value || 0
              )}
            </div>
            <div className="fw-bold text-gray-400">{i.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableSummary;
