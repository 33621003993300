/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { useQueryResponse } from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";
import { useQueryRequest } from "./core/QueryRequestProvider";
import { getReservationUsers } from "../dashboard.service";

type Props = {
  className: string;
  isSummary: boolean;
};

const UserPreRegistrationsChart: React.FC<Props> = ({
  className,
  isSummary,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { state } = useQueryRequest();
  const [data, setData] = useState<any>();
  const { mode } = useThemeMode();
  const intl = useIntl();
  const lang = useLang();
  const [typeOfChart, setTypeOfChart] = React.useState("week");
  const { query } = useQueryResponse();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, result, x, typeOfChart, lang)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    async function getData() {
      const res = await getReservationUsers(state.start_date, state.end_date);
      if (res?.status == 200) setData(res?.data);
    }

    getData();
  }, [state]);

  const NewUserFemale = {
    name: intl.formatMessage({ id: "FEMALE" }),
    data: data?.data?.map((item: { date: any; total_female: any }) => ({
      x: item?.date,
      y: item?.total_female,
    })),
  };

  const NewUserMale = {
    name: intl.formatMessage({ id: "MALE" }),

    data: data?.data?.map((item: { date: any; total_male: any }) => ({
      x: item?.date,
      y: item?.total_male,
    })),
  };

  const NewUserGuest = {
    name: intl.formatMessage({ id: "GUEST" }),

    data: data?.data?.map((item: { date: any; total_guest: any }) => ({
      x: item?.date,
      y: item?.total_guest,
    })),
  };

  const x =
    typeOfChart === "week"
      ? data?.data?.map((obj: { date: string }) => {
          const dataItem = `${obj.date?.substring(5, 7)}/${obj.date?.substring(
            8,
            10
          )}/${obj.date?.substring(0, 4)}`;
          return dataItem || 0;
        })
      : data?.data?.map((obj: { date: string }) => {
          if (obj.date) {
            const hour = parseInt(obj.date.substring(11, 13));
            return hour + "h";
          }
          return 0 + "h";
        });

  const result = [NewUserFemale, NewUserGuest, NewUserMale];

  // const values = Object.values(data);

  useEffect(() => {
    if (query) {
      if ((query as any)?.start_date === (query as any)?.end_date) {
        setTypeOfChart("day");
      } else {
        setTypeOfChart("week");
      }
    }
  }, [query]);

  useEffect(() => {
    let chart: any = null;
    if (data) {
      chart = refreshMode();
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  return (
    <div className={`card`} style={{ height: 400 }}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({
              id: "OVERVIEW.USER_STATUS.NEW_USER",
            })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({
              id: "OVERVIEW.USER_STATUS.NEW_USER_DESCRIPTION",
            })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div
          className="card-p pt-5 flex-grow-1"
          style={{ backgroundColor: "rgb(238 247 255)", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div className="text-center">
              <div className=" text-muted fw-semibold">
                {intl.formatMessage({ id: "TODAY" })}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div
                  className="fs-4 fw-bold"
                  style={{ color: "rgb(0,67,157)" }}
                >
                  <NumberDelay
                    number={data?.today_count + data?.today_guest_count}
                  />
                </div>
                <div
                  className="d-flex gap-2 fs-7"
                  style={{ color: "rgb(0,67,157)" }}
                >
                  <div>
                    {data?.today_count} {intl.formatMessage({ id: "REAL" })}
                  </div>
                  <div>
                    {data?.today_guest_count}{" "}
                    {intl.formatMessage({ id: "GUEST" })}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-center">
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({ id: "TOTAL" })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "black" }}>
                    <NumberDelay
                      number={data?.total_count + data?.total_guest_count}
                    />
                  </div>
                  <div className="d-flex gap-2 fs-7" style={{ color: "black" }}>
                    <div>
                      {data?.total_count} {intl.formatMessage({ id: "REAL" })}
                    </div>
                    <div>
                      {data?.total_guest_count}{" "}
                      {intl.formatMessage({ id: "GUEST" })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Row */}
      </div>
      {/* end::Stats */}

      {/* begin::Chart */}
      <div className="w-100 h-200px">
        <div>
          {/* begin::Chart */}
          <div
            ref={chartRef}
            className={`mixed-widget-3-chart card-rounded-bottom ${"h-150px"}`}
            // data-kt-chart-color={chartColor}
            // style={{ height: chartHeight }}
          ></div>
        </div>
      </div>

      {/* end::Chart */}
      {/* end::Body */}
    </div>
  );
};

const UserPreRegistrationsChartWrapper = ({
  className,
  isSummary = false,
}: any) => (
  <UserPreRegistrationsChart isSummary={isSummary} className={className} />
);

export { UserPreRegistrationsChartWrapper };

function getChartOptions(
  height: number,
  result: any,
  x: any,
  typeOfChart: string,
  lang: string
): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  return {
    series: result,
    chart: {
      type: "bar",
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth:
          x && x.length > 29
            ? "80%"
            : x && x.length > 14
            ? "40%"
            : x && x.length > 7
            ? "40%"
            : x && x.length > 3
            ? "15%"
            : "10%",
      },
    },
    responsive: [
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
    grid: {
      show: true,
      borderColor: "#F4F6FA",
      strokeDashArray: 6,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 25,
      },
    },
    xaxis: {
      categories: x,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: "12px",
          colors: labelColor,
        },
        rotate: 0,
        formatter(value, opts) {
          return typeOfChart === "week"
            ? new Date(value).toLocaleDateString(
                lang === "en" ? "en-us" : "ko-KR",
                {
                  month: "short",
                  day: "2-digit",
                }
              )
            : value;
        },
      },
    },
    yaxis: {
      tickAmount: 3,
      forceNiceScale: true,
      labels: {
        style: {
          colors: labelColor,
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val;
        },
      },
    },
    colors: ["#010e36", "#2177cf", "#65cde7", "#9cd8c0"],

    legend: {
      show: false,
    },
  };
}
