import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageTitle } from "_metronic/layout/core";
import UsersPage from "./pages/users-list-real";
import FakeUsersPage from "./pages/users-list-fake";
import GuestUserPage from "./pages/users-list-guest";
import { useIntl } from "react-intl";
import { lazy } from "react";
import ProfilePage from "./pages/profile/ProfilePage";
import OperatorPage from "./pages/operator";
import PartnerPage from "./pages/partner";
import OperatorDetail from "./pages/operator/operator-detail";

const Registrations = lazy(() => import("./pages/pre-registrations"));
const UserModule = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/real"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "USERS-LIST",
                })}
              </PageTitle>
              <UsersPage />
            </>
          }
        />

        <Route
          path="real/detail/:id/*"
          element={
            <>
              <ProfilePage />
            </>
          }
        />
        <Route
          path="/partner"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "PARTNER-ACCOUNT-LIST",
                })}
              </PageTitle>
              <PartnerPage />
            </>
          }
        />
        <Route
          path="/operating/detail/:id"
          element={
            <>
              <OperatorDetail />
            </>
          }
        />
        <Route
          path="/operating"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "OPERATING-ACCOUNT-LIST",
                })}
              </PageTitle>
              <OperatorPage />
            </>
          }
        />

        <Route
          path="/fake"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "FAKE-LIST",
                })}
              </PageTitle>
              <FakeUsersPage />
            </>
          }
        />

        <Route
          path="/guest"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "GUEST-LIST",
                })}
              </PageTitle>
              <GuestUserPage />
            </>
          }
        />

        <Route
          path="fake/detail/:id/*"
          element={
            <>
              <ProfilePage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default UserModule;
