/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormControl } from "@mui/material";
import { useLang } from "_metronic/i18n/Metronici18n";
import {
  ColorPicker,
  DatePicker,
  Select,
  notification,
  TreeSelect,
} from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputComponent from "./InputComponent";
import { edit_user } from "../../core/_requests";
import clsx from "clsx";
import { ROLE } from "app/constants";
import { useEffect, useState } from "react";
import { getAllKeywords } from "app/_modules/keyword-management/keyword/core/_requests";
import { getAllGroupAPI } from "app/_modules/keyword-management/group-keyword/core/_requests";

const Step1 = ({ data, updateData, refetch, handleClose, info }: any) => {
  const locale = useLang();
  const isEdit = !!info;
  const intl = useIntl();
  const lang = useLang();

  const [allKeywords, setAllKeywords] = useState<any>([]);
  const [allGroup, setAllGroup] = useState<any>();

  useEffect(() => {
    getAllKeyWord();
    getAllGroup();
  }, []);

  async function getAllGroup() {
    const res = await getAllGroupAPI();
    if (res?.status == 200) {
      const data = res?.data?.data;
      const formatData = data?.map((d: any) => {
        return {
          title: d?.name?.[locale],
          value: d?.categories?.map((c: any) => c.id).toString(),
          key: d?.categories?.map((c: any) => c.id).toString(),
          children: d?.categories?.map((c: any) => {
            return {
              title: c?.value?.[locale],
              value: c?.id?.toString(),
              key: c?.id?.toString(),
            };
          }),
        };
      });

      setAllGroup(formatData);
    }
  }

  async function getAllKeyWord() {
    const res = await getAllKeywords();
    if (res?.status == 200) {
      const data = res?.data?.data;
      const formatData = data?.map((d: any) => {
        return {
          value: d?.id,
          label: lang == "ko" ? d?.value?.ko : d?.value?.[locale],
        };
      });

      setAllKeywords(formatData);
    }
  }

  const createEditProductSchema = Yup.object().shape({
    role: Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),
    //  keywords: Yup.array().required(intl.formatMessage({ id: "REQUIRED" })),
  });

  const initialValues = {
    role: data?.role || "",
    keywords: info?.keywords?.map((i: any) => i?.id?.toString()) || [],
  };

  const formik: any = useFormik({
    enableReinitialize: isEdit,
    initialValues,
    validationSchema: createEditProductSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const fortmat_data = {
          role: values?.role,
          keywords: values?.keywords,
        };

        const res = await edit_user(info.id, fortmat_data);
        if (res?.status == 200) {
          notification.success({
            message: info?.id
              ? intl.formatMessage({
                  id: "UPDATE-SUCCESS",
                })
              : intl.formatMessage({
                  id: "CREATE-SUCCESS",
                }),
          });
          handleClose();
          refetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { SHOW_PARENT } = TreeSelect;

  console.log(info);

  const tProps = {
    treeData: allGroup,
    value: formik?.values?.keywords,
    onChange: (values: any) => {
      if (values?.length == 0) {
        formik.setFieldValue("keywords", []);
        return;
      }
      const finalIds = values.toString()?.split(",");
      console.log(finalIds);
      formik.setFieldValue("keywords", finalIds);
    },
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };

  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm min-w-400px"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          {/* KO */}
          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "ROLE" })}
              </span>
            </label>
            <Select
              showArrow
              placeholder={intl.formatMessage({ id: "SELECT-ROLE" })}
              value={formik.values?.role}
              filterOption={(inputValue: any, option: any) => {
                return option.children
                  ?.toString()
                  ?.concat(option?.value?.toString())
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
              onChange={(value) => {
                formik.setFieldValue("role", value);
              }}
              className={clsx(
                {
                  "is-invalid": formik.errors.role_id,
                },
                {
                  "is-valid": formik.values?.role_id && !formik.errors.role_id,
                }
              )}
            >
              {ROLE?.map((option: any) => (
                <Select.Option key={option.value}>
                  {intl.formatMessage({ id: option.label })}
                </Select.Option>
              ))}
            </Select>
            {formik.errors.role_id && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  {formik.errors.role_id}
                </div>
              </div>
            )}
          </div>

          {/* VI */}

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="">{intl.formatMessage({ id: "KEYWORD" })}</span>
            </label>
            <TreeSelect {...tProps} />
            {/* <Select
              mode="multiple"
              showArrow
              placeholder={intl.formatMessage({ id: "SELECT-KEYWORD" })}
              value={formik.values?.keywords}
              filterOption={(inputValue: any, option: any) => {
                return option.children
                  ?.toString()
                  ?.concat(option?.value?.toString())
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
              onChange={(value) => {
                console.log(value);
                formik.setFieldValue("keywords", value);
              }}
              className="w-100"
            >
              {allKeywords?.map((option: any) => (
                <Select.Option key={option.value}>{option.label}</Select.Option>
              ))}
            </Select> */}
            {formik.errors.role_id && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  {formik.errors.role_id}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-stack justify-content-end">
        <button
          disabled={formik.isSubmitting || !formik.isValid}
          className="btn btn-lg btn-primary"
          type="submit"
        >
          {intl.formatMessage({ id: "SAVE" })}
        </button>
      </div>{" "}
    </form>
  );
};

export { Step1 };
