import axios, { AxiosResponse } from "axios";
import { UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/transactions/purchase-history`;
const GET_OPERATOR_USERS_URL = `${API_URL}/user/active-users?`;

export const getPaginationPosts = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  if (!query?.operatorIds?.length) query = { ...query, operatorIds: [-1] };
  const res = await axios.get(`${GET_USERS_URL}`, {
    params: query,
  });
  const { data, count } = res.data;
  const pagination = {
    page: newquery.page,
    total: count,
    limit: newquery.limit,
  };

  return {
    data: data as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
  };
};

export const getUsersInOPerator = async (operator_ids: any) => {
  const res = await axios.get(`${GET_OPERATOR_USERS_URL}`, {
    params: {
      operator_ids,
      page: 1,
      limit: 10000,
      gender: "FEMALE",
      userType: "FAKE",
    },
  });
  const { data } = res.data;
  return data;
};

export const getStatisticContentSale = async (query: any) => {
  if (!query?.operatorIds?.length) query = { ...query, operatorIds: [-1] };

  const res = await axios.get(`${GET_USERS_URL}/stat`, {
    params: { ...query },
  });

  return res.data;
};
