import Dashboard from "app/_modules/dashboard";
import UserPage from "app/_modules/user-management";
import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

import { QueryRequestProvider } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import { QueryResponseProvider } from "app/_modules/dashboard/components/core/QueryResponseProvider";
import ProductPage from "app/_modules/product-management";
import PurchaseHistoryPage from "app/_modules/transaction-history";
import ContentSaleHistoryPage from "app/_modules/content-sale-history";
import PlacePage from "app/_modules/place-management";
import KeywordPage from "app/_modules/keyword-management/keyword";
import CategoryPage from "app/_modules/category-management";
import GeneralSetting from "app/_modules/others/setting/general-setting";
import AgencyModule from "app/_modules/agency-management";

const PrivateRoutes = () => {
  // const AgencyModule = lazy(() => import("app/_modules/agency-management"));
  const UserManagementModule = lazy(
    () => import("app/_modules/user-management")
  );

  const PlaceListModule = lazy(() => import("app/_modules/place-management"));

  const ReservationModule = lazy(
    () => import("app/_modules/reservation-event")
  );

  const ExploreKeywordSetting = lazy(
    () => import("app/_modules/others/setting/explore-setting")
  );

  const KeywordModule = lazy(() => import("app/_modules/keyword-management"));

  const PaymentModule = lazy(() => import("app/_modules/payment"));

  const ReportModule = lazy(() => import("app/_modules/report"));

  const FAQModule = lazy(() => import("app/_modules/faq-management"));

  const AdminManagementModule = lazy(
    () => import("app/_modules/admin-management")
  );

  const OthersModule = lazy(() => import("app/_modules/others"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />
        <Route
          path="dashboard/*"
          element={
            <QueryRequestProvider>
              <QueryResponseProvider>
                <Dashboard />
              </QueryResponseProvider>
            </QueryRequestProvider>
          }
        />
        {/*  <Route path="contents/*" element={<ContentsPage />} /> */}
        <Route path="user/*" element={<UserPage />} />

        <Route
          path="admin-management/*"
          element={
            <SuspensedView>
              <AdminManagementModule />
            </SuspensedView>
          }
        />

        <Route path="agency-management/*" element={<AgencyModule />} />

        <Route
          path="user-management/*"
          element={
            <SuspensedView>
              <UserManagementModule />
            </SuspensedView>
          }
        />

        <Route
          path="product-management/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />

        <Route
          path="payment/*"
          element={
            <SuspensedView>
              <PaymentModule />
            </SuspensedView>
          }
        />

        <Route
          path="place-management/category"
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />

        <Route
          path="place-management/list/*"
          element={
            <SuspensedView>
              <PlaceListModule />
            </SuspensedView>
          }
        />

        <Route
          path="content-sale-management/*"
          element={
            <SuspensedView>
              <ContentSaleHistoryPage />
            </SuspensedView>
          }
        />

        <Route
          path="purchase-management/*"
          element={
            <SuspensedView>
              <PurchaseHistoryPage />
            </SuspensedView>
          }
        />

        <Route
          path="keyword/*"
          element={
            <SuspensedView>
              <KeywordModule />
            </SuspensedView>
          }
        />

        <Route
          path="faq/*"
          element={
            <SuspensedView>
              <FAQModule />
            </SuspensedView>
          }
        />

        <Route
          path="reservation/*"
          element={
            <SuspensedView>
              <ReservationModule />
            </SuspensedView>
          }
        />

        <Route
          path="explore-setting/*"
          element={
            <SuspensedView>
              <ExploreKeywordSetting />
            </SuspensedView>
          }
        />

        <Route
          path="report/*"
          element={
            <SuspensedView>
              <ReportModule />
            </SuspensedView>
          }
        />

        <Route
          path="others/*"
          element={
            <SuspensedView>
              <OthersModule />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
      "1.0": baseColor,
    },
    barThickness: 3,
    shadowBlur: 3,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
