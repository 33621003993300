import axios, { AxiosResponse } from "axios";
import { UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import { callApiWithTryCatch } from "app/utils/apiUntils";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/group-categories`;

const edit_product = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}`, body);
};

const delete_product = (id: any) => {
  return axios.delete(`${GET_USERS_URL}/${id}`);
};

const create_product = (body: any) => {
  return axios.post(`${GET_USERS_URL}`, body);
};

const revert_post = (id: any) => {
  return axios.put(`${GET_USERS_URL}/restore/${id}`);
};

export const getPaginationPosts = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  // if (!newquery.is_active) {
  //   newquery.is_active = false;
  // }
  const res = await axios.get(`${GET_USERS_URL}/group-keyword`, {
    params: newquery,
  });
  const { data, count } = res.data;
  const pagination = {
    page: newquery.page,
    total: count,
    limit: newquery.limit,
  };
  return {
    data: data as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
  };
};

function getAllGroupAPI() {
  return axios.get(`${GET_USERS_URL}`, {
    params: {
      page: 1,
      limit: 1000,
    },
  });
}

export {
  edit_product,
  delete_product,
  create_product,
  revert_post,
  getAllGroupAPI,
};
