import {
  KTCard,
  KTIcon,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { usersColumns } from "./table/_columns";

import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useAuth } from "app/_modules/auth";
import { getPaginationPosts } from "./core/_requests";
import { CreateAppModal } from "./components/edit-product";

interface Props {
  className?: string;
  data?: any;
  pagination?: any;
  type?: string;
  columns?: any;
}
export const UsersList = ({ data, pagination, type, columns }: Props) => {
  const users: any = useQueryResponseData();
  const dataUser = useMemo(() => users, [users]);

  const { allPermission, myPermission } = useAuth();

  const intl = useIntl();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  let cardList = [
    {
      title: intl.formatMessage({
        id: "OPERATOR",
      }),
      value: pag?.total || 0,
    },

    {
      title: intl.formatMessage({
        id: "TOTAL-ASSIGNED",
      }),
      value: pag?.countUsersAssigned || 0,
    },
  ];

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_USER,
    allPermission,
    myPermission
  );

  let permissionColumns = useMemo(
    () => usersColumns(hasPermissionDelete),
    [allPermission, myPermission]
  );
  const columnUse = useMemo(() => {
    return type === "comment_count" ? columns : permissionColumns;
  }, [permissionColumns]);

  return (
    <>
      <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.OPERATING} />
      <KTCard>
        <div className="d-flex align-items-center justify-content-between">
          <TableSummary cardList={cardList} />
          <CreateProductModal />
        </div>
        <Table
          defineColumns={columnUse}
          data={data || dataUser}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          pagination={pagination}
          // className="frenzy"
        />
      </KTCard>
    </>
  );
};

function CreateProductModal({}) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };
  const intl = useIntl();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="mx-5"
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="plus" className="fs-2" />
          {intl.formatMessage({ id: "CREATE" })}
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={null}
        />
      )}
    </>
  );
}

const UsersListWrapper = () => (
  <TableWrapper
    getPanigation={getPaginationPosts}
    children={<UsersList />}
  ></TableWrapper>
);

export default UsersListWrapper;
