/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { KTIcon, initialQueryState } from "_metronic/helpers";

const RowCountComponent = ({ useQueryRequest, state, updateState }: any) => {
  const numbers = [50, 100, 200];
  const [selection, setSelection] = useState(10);
  // const { updateState, state } = useQueryRequest();

  useEffect(() => {
    if (state?.items_per_page) {
      setSelection(state.items_per_page);
    }
  }, [state?.items_per_page]);

  return (
    <div className="card-title">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative">
        <div
          className="d-flex align-items-center btn btn-light btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <span style={{ paddingRight: "0.35rem" }}>{selection}</span>
          <KTIcon iconName="down" className="fs-5 m-0" />
        </div>
        {/* begin::Menu */}
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
          data-kt-menu="true"
        >
          {numbers.map((i) => (
            <div
              className="menu-item px-3"
              key={Math.random()}
              onClick={() => {
                setSelection(i);
                updateState({ ...initialQueryState, items_per_page: i });
              }}
            >
              <a className="menu-link px-3">{i}</a>
            </div>
          ))}
        </div>
        {/* end::Menu */}
      </div>
      {/* end::Search */}
    </div>
  );
};

export { RowCountComponent };
