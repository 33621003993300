// @ts-nocheck
import { useIntl } from "react-intl";

import { KTIcon } from "_metronic/helpers";
import { notification, Switch, Popconfirm } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
// import { deleteNotify } from "../_requests";
import { CreateAppModal } from "../components/edit-agency";
import { useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { deleteAgency, editAgency } from "../core/_requests";
import {
  detachPhoneCountryCode,
  formatNumberBeforeSend,
} from "app/utils/apiUntils";
import { ChangePasswordForm } from "../components/change-password-form";

const usersColumns: any = () => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="STATUS"
        className="min-w-50px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "status",
    accessor: "status",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <EditStatus row={item} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="AGENCYID"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "agency",
    accessor: "agency",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <div>{item.code}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="AGENCY-NAME"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "name",
    accessor: "name",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CONTACT-NAME"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "contactName",
    accessor: "contactName",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PHONE_NUMBER"
        className="min-w-50px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "phone",
    accessor: "phone",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      const lang = JSON.parse(localStorage.getItem("i18nConfig"));
      // let phoneCode = formatNumberBeforeSend(item?.countryCode, item?.phoneNumber);
      let phoneCode = item?.phone
        ? detachPhoneCountryCode(item.phone)?.phoneCode
        : formatNumberBeforeSend(item?.countryCode, item?.phone);
      return <div>{phoneCode}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="EMAIL"
        className="min-w-100x text-center"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
      />
    ),
    id: "email",
    accessor: "email",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REFERAL-CODE"
        className="min-w-100x text-center"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
      />
    ),
    id: "referralCode",
    accessor: "referralCode",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="LINK"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "link",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const intl = useIntl();

      const handleCopy = (text?: string) => {
        navigator.clipboard.writeText(text);
        notification.success({
          message: intl.formatMessage({ id: "COPY_CLIPBOARD" }),
        });
      };
      return (
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div>{row.shortLink}</div>{" "}
          {row.shortLink && (
            <span
              onClick={() => handleCopy(row?.shortLink)}
              className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary"
              style={{ marginLeft: 8 }}
            >
              <KTIcon className="fs-1 cursor-pointer" iconName="copy" />
            </span>
          )}
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="COMMISSION-PERCENTAGE"
        className="min-w-100x text-center"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "commissionPercentage",
    accessor: "commissionPercentage",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="SERVICE-FEE-PERCENTAGE"
        className="min-w-100x text-center"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "serviceFeePercentage",
    accessor: "serviceFeePercentage",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "createdAt",
    accessor: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <CreateDate date={item.createdAt} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "updatedAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <CreateDate date={item.updatedAt} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        className=""
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action-delete",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props?.row?.original;
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ gap: 5 }}
        >
          <EditNotifyModal row={props?.row?.original} />
          <ChangePasswordModal row={props?.row?.original} />
          <DeleteComponent row={row} />
        </div>
      );
    },
  },
];

const CreateDate = ({ date }: any) => {
  let day_kr = moment.utc(date).tz("Asia/Seoul").format("YYYY.MM.DD");
  let time_kr = moment.utc(date).tz("Asia/Seoul").format("HH:mm:ss");

  let day_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("YYYY.MM.DD");
  let time_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("HH:mm:ss");
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex align-items-start gap-1">
        <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
          KR: {day_kr}
        </div>
        <div className="text-muted fw-semibold text-muted d-block fs-7">
          {time_kr}
        </div>
      </div>
      <div className="d-flex flex align-items-start gap-1">
        <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
          VN: {day_vn}
        </div>
        <div className="text-muted fw-semibold text-muted d-block fs-7">
          {time_vn}
        </div>
      </div>
    </div>
  );
};

function EditStatus({ row }) {
  const { refetch } = useQueryResponse();
  const [isActiveInactive, setIsActiveInactive] = useState<boolean>(false);

  return (
    <Switch
      size="small"
      onChange={async (value) => {
        const body = {
          id: row.id,
          isActive: value,
        };
        const res = await editAgency(body);
        if (res?.success === true) setIsActiveInactive(!isActiveInactive);
      }}
      defaultChecked={row?.isActive}
      // checked={row?.isActive}
    />
  );
}

function EditNotifyModal({ row }) {
  const intl = useIntl();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="notepad-edit" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
          info={row}
        />
      )}
    </>
  );
}

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const intl = useIntl();
  const [isWaringDelete, setIsWaringDelete] = useState<boolean>();
  const handleToggleModalView = () => {
    setIsWaringDelete(!isWaringDelete);
  };
  const handleDelete = async () => {
    setIsLoadingCreate(true);
    const res: any = await deleteAgency(row?.id);
    if (res.success === true) {
      notification.success({
        message: "Delete success",
        duration: 3,
      });
      refetch();
    }
    setIsLoadingCreate(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "AGENCY.DELETE.CONFIRM" })}
        onConfirm={handleDelete}
        onCancel={handleToggleModalView}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary"
          disabled={row.referredCount > 0}
        >
          {isLoadingCreate ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon
              iconName="trash"
              className="fs-2 color-primary fill-primary"
            />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

function ChangePasswordModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="password-check" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <ChangePasswordForm
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}
export { usersColumns };
