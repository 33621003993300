/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormControl } from "@mui/material";
import { useLang } from "_metronic/i18n/Metronici18n";
import { ColorPicker, DatePicker, Select, notification } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputComponent from "./InputComponent";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { toAbsoluteUrlENV } from "_metronic/helpers";
import { editAgency } from "../../core/_requests";

const Step1 = ({ refetch, handleClose, info }: any) => {
  const isEdit = !!info;
  const intl = useIntl();
  const lang = useLang();

  const data = info;

  const createEditProductSchema = Yup.object().shape({
    password: isEdit
      ? Yup.string().nullable().notRequired()
      : Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),
  });

  const initialValues = {
    password: data?.password || "",
  };

  const formik: any = useFormik({
    enableReinitialize: isEdit,
    initialValues,
    validationSchema: createEditProductSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const fortmat_data = {
          id: info.id,
          password: values?.password,
        };
        let res;
        res = await editAgency(fortmat_data);
        console.log(res);

        if (res?.success) {
          notification.success({
            message: info?.id
              ? intl.formatMessage({
                  id: "UPDATE-SUCCESS",
                })
              : intl.formatMessage({
                  id: "CREATE-SUCCESS",
                }),
          });
          handleClose();
          refetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm min-w-400px"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          {/* EN */}
          <div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({
                  id: "PASSWORD",
                })}
                fieldKey="password"
                inputType="password"
                isRequired={isEdit ? false : true}
              />
            </div>
          </div>

          {/* VI */}
        </div>
      </div>
      <div className="d-flex flex-stack justify-content-end">
        <button className="btn btn-lg btn-primary" type="submit">
          {intl.formatMessage({ id: "SAVE" })}
        </button>
      </div>{" "}
    </form>
  );
};

export { Step1 };
