import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import AgencyList from "./pages/agency-list";
import Revenuelist from "./pages/revenue-management";
import WithdrawList from "./pages/withdraw-management";
import { useIntl } from "react-intl";
import { PageTitle } from "_metronic/layout/core";

const AgencyModule = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "AGENCY-LIST",
                })}
              </PageTitle>
              <AgencyList />
            </>
          }
        />

        <Route
          path="/revenue"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "REVENUE",
                })}
              </PageTitle>
              <Revenuelist />
            </>
          }
        />

        <Route
          path="/withdraw"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "WITHDRAW",
                })}
              </PageTitle>
              <WithdrawList />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default AgencyModule;
