import { PageTitle } from "_metronic/layout/core";
import { useIntl } from "react-intl";
import { Outlet, Route, Routes } from "react-router-dom";
import ListCategory from "./pages/list-category";

const CategoryPage = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle>{intl.formatMessage({ id: "CATEGORY" })}</PageTitle>
              <ListCategory />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default CategoryPage;
