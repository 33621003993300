import { useIntl } from "react-intl";

export default function InputComponent({
  formik,
  label,
  fieldKey,
  inputType = "text",
}: any) {
  const intl = useIntl();

  return (
    <div className="fv-row col mb-10">
      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
        <span className="required">{label}</span>
      </label>
      <input
        className="form-control form-control-lg form-control-solid"
        placeholder={intl.formatMessage({ id: "TYPE-IN-HERE" })}
        type={inputType}
        {...formik.getFieldProps(fieldKey)}
      />
      {formik.touched[fieldKey] && formik.errors[fieldKey] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik.errors[fieldKey]?.toString()}</span>
          </div>
        </div>
      )}
    </div>
  );
}
