/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { CreateProduct } from "../core/_models";
import { Step1 } from "./changePassword/Step1";

const modalsRoot = document.getElementById("root-modals") || document.body;

const ChangePasswordForm = ({ show, handleClose, info }: any) => {
  const intl = useIntl();

  const { refetch } = useQueryResponse();

  const [data, setData] = useState<CreateProduct | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoadingCreateAdmin, setIsLoadingCreate] = useState(false);

  const handleOnCloseModal = () => {
    setHasError(false);
    setData(null);
    handleClose();
  };

  useEffect(() => {
    setData({ ...info });
  }, [info]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px admin-info-modal"
      show={show}
      onHide={handleOnCloseModal}
      backdrop={true}
      onBackdropClick={handleOnCloseModal}
    >
      <div className="modal-header">
        <h2>
          {info
            ? intl.formatMessage({ id: "EDIT" })
            : intl.formatMessage({ id: "CREATE" })}
        </h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleOnCloseModal}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div
        className="modal-body py-lg-10 px-lg-10"
        // style={{ minWidth: "750px" }}
      >
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          {/*begin::Content */}
          <div style={{ minWidth: "100%" }}>
            {/*begin::Form */}
            <Step1
              data={data}
              updateData={setData}
              hasError={hasError}
              info={info}
              refetch={refetch}
              handleClose={handleClose}
            />
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { ChangePasswordForm };
