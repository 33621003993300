import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { usersColumns } from "./table/_columns";

import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useAuth } from "app/_modules/auth";
import {
  getPanigationREALUsers,
  getPanigationFAKEUsers,
} from "./core/_requests";

interface Props {
  className?: string;
  data?: any;
  pagination?: any;
  type?: string;
  columns?: any;
}
export const UsersList = ({ data, pagination, type, columns }: Props) => {
  const users: any = useQueryResponseData();
  const dataUser = useMemo(() => users, [users]);

  const { allPermission, myPermission } = useAuth();

  const intl = useIntl();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  let cardList = [
    /* {
      title: intl.formatMessage({
        id: "USER",
      }),
      value: pag?.total || 0,
    }, 

    {
      title: intl.formatMessage({
        id: "MALE",
      }),
      value: res?.response?.full_res?.total_male_count || 0,
    },*/

    {
      title: intl.formatMessage({
        id: "FEMALE",
      }),
      value: res?.response?.full_res?.total_female_count || 0,
    },
  ];

  cardList.sort((a, b) => {
    return b.value - a.value;
  });

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_USER,
    allPermission,
    myPermission
  );

  let permissionColumns = useMemo(
    () => usersColumns(hasPermissionDelete),
    [allPermission, myPermission]
  );
  const columnUse = useMemo(() => {
    return type === "comment_count" ? columns : permissionColumns;
  }, [permissionColumns]);

  return (
    <>
      <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.USER_GUEST} />
      <KTCard>
        <TableSummary cardList={cardList} />
        <Table
          defineColumns={columnUse}
          data={data || dataUser}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          pagination={pagination}
          // className="frenzy"
        />
      </KTCard>
    </>
  );
};

const UsersListWrapper = () => {
  if (window.location?.pathname?.includes("real"))
    return (
      <TableWrapper
        getPanigation={getPanigationREALUsers}
        children={<UsersList />}
      ></TableWrapper>
    );
  else
    return (
      <TableWrapper
        getPanigation={getPanigationFAKEUsers}
        children={<UsersList />}
      ></TableWrapper>
    );
};

export default UsersListWrapper;
