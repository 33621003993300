import {
  KTCard,
  KTIcon,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { Table } from "app/_components/table/Table";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { getPaginationPosts } from "./core/_requests";
import { usersColumns } from "./table/_columns";
import { useAuth } from "app/_modules/auth";
import { CreateAppModal } from "./components/edit-product";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";

const ProductList = () => {
  const users = useQueryResponseData();
  const data: any = useMemo(() => users, [users]);

  const intl = useIntl();

  const res: any = useQueryResponse();
  const pag = useQueryResponsePagination();

  const { allPermission, myPermission } = useAuth();

  const [row, setRow] = useState<any>(null);
  const [isOpenModalLike, setIsOpenModalLike] = useState(false);
  const [type, setType] = useState("");
  const articleType = res?.response?.articleType;

  const updateRow = (row: any, type: string) => {
    setType(type);

    setIsOpenModalLike(true);
    setRow(row);
  };

  let cardList = [
    {
      title: intl.formatMessage({
        id: "PRODUCT",
      }),
      value: pag?.total,
    },
  ];

  if (articleType?.length) {
    cardList = [
      ...cardList,
      {
        title: intl.formatMessage({
          id: "GOAL",
        }),
        value: articleType[0]?.goal_count || "0",
      },

      {
        title: intl.formatMessage({
          id: "WISHBOARD",
        }),
        value: articleType[0]?.wish_board_count || "0",
      },
    ];
  }

  // const hasPermissionDelete = hasPermission(
  //   PERMISSIONS_MAP.REMOVE_PRODUCT,
  //   myPermission,
  //   allPermission
  // );

  // const hasPermissionUpdate = hasPermission(
  //   PERMISSIONS_MAP.UPDATE_PRODUCT,
  //   myPermission,
  //   allPermission
  // );

  let permissionColumns = useMemo(
    () => usersColumns(true, true),
    [allPermission, myPermission]
  );

  // const hasPermissionCreate = hasPermission(
  //   PERMISSIONS_MAP.CREATE_PRODUCT,
  //   myPermission,
  //   allPermission
  // );
  const hasPermissionCreate = true;

  return (
    <div>
      {!data ? null : (
        <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.PRODUCT} />
      )}
      <KTCard>
        <div className="d-flex align-items-center justify-content-between">
          <TableSummary cardList={cardList} />

          {hasPermissionCreate ? <CreateProductModal /> : null}
        </div>
        <Table
          // defineColumns={
          //   hasPermissionDelete || hasPermissionUpdate
          //     ? permissionColumns
          //     : permissionColumns.filter((i: any) => i.id !== "action")
          // }
          defineColumns={permissionColumns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          // className="frenzy"
        />
      </KTCard>
    </div>
  );
};

const UsersListWrapper = () => (
  <TableWrapper module="post" getPanigation={getPaginationPosts}>
    <ProductList />
  </TableWrapper>
);

function CreateProductModal({}) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };
  const intl = useIntl();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="mx-5"
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="plus" className="fs-2" />
          {intl.formatMessage({ id: "CREATE" })}
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={null}
        />
      )}
    </>
  );
}

export default UsersListWrapper;
