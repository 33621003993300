import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import axios from "axios";
import { UsersQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/user/active-users?`;

export const getPanigationREALUsers = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  const res = await axios.get(`${GET_USERS_URL}`, {
    params: { ...newquery, type: "GUEST" },
  });

  const { data, count } = res.data;
  const pagination = {
    page: newquery.page,
    total: count,
    limit: newquery.limit,
  };

  return {
    data: data as UsersQueryResponse,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    full_res: res?.data,
  };
};

export const getPanigationFAKEUsers = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  const res = await axios.get(`${GET_USERS_URL}`, {
    params: { ...newquery, type: "GUEST" },
  });

  const { data, count } = res.data;
  const pagination = {
    page: newquery.page,
    total: count,
    limit: newquery.limit,
  };

  return {
    data: data as UsersQueryResponse,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    full_res: res?.data,
  };
};

const edit_user = (id: any, body: any) => {
  return axios.put(`${API_URL}/user/${id}`, body);
};

const delete_admin = (id: any) => {
  return axios.delete(`${GET_USERS_URL}/${id}/delete`);
};

export { delete_admin, edit_user };
