import countryCodes from "../constants/countryCode.json";
interface ApiResponse {
  statusCode: number;
  data: any;
  message: string;
  title?: string;
  pagination?: any;
  countAgencies?: number;
  customer?: any;
  countRecommender?: number;
  sumReferred?: number;
  totalReferred?: number;
  experiment?: any;
}

export async function callApiWithTryCatch<T>(
  apiFunction: () => Promise<T>,
  setLoadingCallback?: (isLoading: boolean) => void
): Promise<ApiResponse> {
  try {
    setLoadingCallback && setLoadingCallback(true);
    const response: any = await apiFunction();
    return {
      data: response.data.data,
      pagination: {total: response?.data?.total || response?.data?.count},
      message: response.data.message,
      title: response.data?.title ? response.data.title : "",
      statusCode: response.data?.statusCode,
      countAgencies: response.data?.countAgencies,
      customer: response.data?.customer,
      countRecommender: response.data?.countRecommender,
      sumReferred: response.data?.sumReferred,
      totalReferred: response.data?.totalReferred,
      experiment: response.data,
    };
  } catch (error: any) {
    return {
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      title: error?.response?.data?.title,
      data: null,
    };
  } finally {
    setLoadingCallback && setLoadingCallback(false);
  }
}

export const formatPhone = (text: string) => {
  if(!text) return;
  return text.replace(/^(.{3})(.{6})(.*)$/, "$1 $2$3");
}

export const localStartDateToISO = (time: string) => {
  let startDate = new Date(time);
  startDate.setHours(0, 0, 0, 0);
  return startDate.toISOString();
};
export const localEndDateToISO = (time: string) => {
  let endDate = new Date(time);
  endDate.setHours(23, 59, 59, 999);
  return endDate.toISOString();
};

export function formatNumberBeforeSend(prefix: any, num: any) {
  if (!num) return;
  num = num?.toString();
  while (num?.charAt(0) === "0") {
    num = num?.substring(1);
  }
  return prefix + num;
}

export function analyticsCountryCode() {
  let analyticsCountryCodeData = {
    maxLength: 0,
    value: {},
    isRunAnalytic: false,
  } as any;
  if (analyticsCountryCodeData.isRunAnalytic) return true;

  for (let i = 0; i < countryCodes.length; i++) {
    const countryCodeItem = countryCodes[i].countryCodes[0];
    const maxLength = countryCodeItem.length;
    analyticsCountryCodeData.value[countryCodeItem] = countryCodeItem;
    if (maxLength > analyticsCountryCodeData.maxLength) analyticsCountryCodeData.maxLength = maxLength;
  }
  return analyticsCountryCodeData;
}

export function detachPhoneCountryCode(phone: any) {
  let analyticCountry = analyticsCountryCode();
  let result = {
    code: "",
    phone: "",
    phoneCode: "",
  }
  for (let i = analyticCountry.maxLength; i > 0; i--) {
    const code = String(phone).substring(0, i);
    if (analyticCountry.value[code]) {
      result = {
        code: code,
        phone: phone.replace(code, ''),
        phoneCode: phone.replace(code, `${code} `)
      }
      return result;
    }
  }
  return phone;
}
export function stringInterpolate(theString: string, argumentArray: any) {
  var regex = /%s/;
  var _r = function (p: string, c: any) { return p.replace(regex, c); }
  return argumentArray.reduce(_r, theString);
}
