import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CountryCode from "app/constants/countryCode.json";
import { useIntl } from "react-intl";
import { KTIcon, useDebounce } from "_metronic/helpers";
type SearchPhoneCountryCodeProps = {
  onClose?: VoidFunction;
  selectCountry?: any;
};
const SearchPhoneCountryCode = ({
  onClose,
  selectCountry,
}: SearchPhoneCountryCodeProps) => {
  const intl = useIntl();
  const [dataCountry, setDataCountry] = useState<any[]>(CountryCode);
  const [isSearchKeyword, setIsSearchKeyword] = useState<any>("");
  const debouncedSearchTerm = useDebounce(isSearchKeyword, 500);

  useEffect(() => {
    async function filterData(){
      if (!debouncedSearchTerm) {
        setDataCountry(CountryCode);
        return;
      }
      const filterCountries = await CountryCode.filter((i: any) => {
        return i?.name
          .toLowerCase()
          .includes(debouncedSearchTerm?.toLocaleLowerCase()) || i?.countryCodes.join(' ')
          .includes(debouncedSearchTerm?.toLocaleLowerCase());
      });
  
      setDataCountry(filterCountries);
    };
    filterData();
  }, [debouncedSearchTerm]);

 
  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-800px role-info-modal"
      show={true}
      onHide={onClose}
      backdrop={true}
      // onBackdropClick={onClose}
    >
      <div className="modal-header">
        <div className="d-flex flex-column w-full">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary align-self-end"
            onClick={onClose}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          <div className="d-flex flex-row w-full align-items-center text-center">
            <h2 className="w-full fs-1 mb-5 fw-bold">
              {intl.formatMessage({ id: "CHOOSE-COUNTRY" })}
            </h2>
          </div>
          <form className="w-full">
            <input
              className="form-control form-control-lg form-control-solid"
              placeholder={intl.formatMessage({
                id: "CHOOSE-COUNTRY_PLACEHOLDER",
              })}
              value={isSearchKeyword}
              onChange={(e) => {
                setIsSearchKeyword(e.target.value);
              }}
            />
          </form>
        </div>
      </div>
      <div className="modal-body pb-lg-10 px-lg-10 modal-country  overflow-auto">
        {dataCountry.map((item) => {
          return (
            <div
              key={item?.name}
              className="d-flex flex-row gap-2 text-gray-600 fw-bold my-5 fs-6 cursor-pointer"
              onClick={() => selectCountry(item)}
            >
              <span>{item.flag}</span>
              <span>{item.name}</span>
              <span>{item.countryCodes.join(" ")}</span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
export default SearchPhoneCountryCode;
