import { KTIcon, toAbsoluteUrlENVDefaultWorker } from "_metronic/helpers";
import axios from "axios";
import { useEffect, useState } from "react";

export async function getImageFromWorker(imageURL: string) {
  return await axios.get(imageURL, {
    responseType: "blob",
  });
}

export default function AsyncImage({ i }: any) {
  const [renderBlob, setRenderBlob] = useState<any>();
  useEffect(() => {
    if (!i?.url?.length || renderBlob) return;
    if (i?.url?.includes("worker")) fortmatImage(i?.url);
    else {
      const absolutURL = toAbsoluteUrlENVDefaultWorker(i?.url);
      //setRenderBlob(absolutURL);
      fortmatImage(absolutURL);
    }
  }, [i?.url]);

  async function fortmatImage(url: string) {
    const resImgWorker = await getImageFromWorker(url);
    //console.log(resImgWorker);

    if (resImgWorker?.status == 200) {
      const blobImg = URL.createObjectURL(resImgWorker?.data);
      setRenderBlob(blobImg);
    }
  }
  return (
    <div className="symbol-label overflow-hidden mb-2 position-relative">
      <a
        data-fancybox="gallery"
        href={renderBlob}
        key={Math.random()}
        className=""
      >
        {/*  <img
          src={renderBlob}
          style={{ maxHeight: 200, maxWidth: 200 }}
          className="position-relative"
        /> */}
        img
        {Number(i?.price) > 0 && (
          <div className="position-absolute top-0" style={{ right: 5 }}>
            {i?.lock ? (
              <div className="fs-6">{Number(i?.price)}</div>
            ) : (
              <KTIcon iconName="dollar" className="fs-2" />
            )}
          </div>
        )}
      </a>
    </div>
  );
}
