import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

const Draggable = ({
  rootClass = "",
  children,
  isHiddenHeader = false,
}: any) => {
  // We will use React useRef hook to reference the wrapping div:
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

  return (
    <div
      className={`flex max-w-xl space-x-3 ${
        isHiddenHeader ? "" : " overflow-x-scroll scrollbar-hide"
      }`}
      {...events}
      ref={ref} // add reference and events to the wrapping div
    >
      {children}
    </div>
  );
};

export default Draggable;
