import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "_metronic/layout/core";
import ListProduct from "./pages/list-product";
import { useIntl } from "react-intl";
import { QueryRequestProvider } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import { QueryResponseProvider } from "app/_modules/dashboard/components/core/QueryResponseProvider";

const ProductPage = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle>{intl.formatMessage({ id: "PRODUCT" })}</PageTitle>
              <ListProduct />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProductPage;
