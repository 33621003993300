/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from "app/_modules/auth";
import { useIntl } from "react-intl";
import { PERMISSIONS_MAP } from "../../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { myPermission, allPermission } = useAuth();

  const hasPermission = (permissionName: any) => {
    const userPermission = myPermission.find(
      (perm: any) => perm.name === permissionName
    );

    const allPermissionArray = allPermission.find(
      (perm: any) => perm.name === permissionName
    );

    return (
      userPermission &&
      allPermissionArray &&
      // eslint-disable-next-line eqeqeq
      userPermission.id == allPermissionArray.id
    );
  };

  const canViewUserManagement =
    hasPermission(PERMISSIONS_MAP.USER_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_DELETED) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_LOG);

  const canViewAdminManagement =
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN) ||
    hasPermission(PERMISSIONS_MAP.LIST_ROLE) ||
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN_LOG);

  const canViewContentManagement =
    hasPermission(PERMISSIONS_MAP.CONTENT_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_VIDEO) ||
    hasPermission(PERMISSIONS_MAP.LIST_POST) ||
    hasPermission(PERMISSIONS_MAP.LIST_SHARE) ||
    hasPermission(PERMISSIONS_MAP.LIST_HASHTAG);

  const canViewAlarmManagement =
    hasPermission(PERMISSIONS_MAP.LIST_PUSH) ||
    hasPermission(PERMISSIONS_MAP.LIST_EVENT) ||
    hasPermission(PERMISSIONS_MAP.LIST_NOTICE);

  const canViewSettingManagement =
    hasPermission(PERMISSIONS_MAP.LIST_AD_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_APP_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_RANKING_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_TERM) ||
    hasPermission(PERMISSIONS_MAP.LIST_LOCALIZATION_SETTING);

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      <SidebarMenuItemWithSub
        to="/user-management"
        title={intl.formatMessage({ id: "USER-MANAGEMENT" })}
        fontIcon="bi-chat-left"
        icon="profile-circle"
      >
        <SidebarMenuItem
          to="/user-management/real"
          title={intl.formatMessage({ id: "USERS-LIST" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/user-management/partner"
          title={intl.formatMessage({ id: "PARTNER-ACCOUNT-LIST" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/user-management/operating"
          title={intl.formatMessage({ id: "OPERATING-ACCOUNT-LIST" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/user-management/fake"
          title={intl.formatMessage({ id: "FAKE-LIST" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/user-management/guest"
          title={intl.formatMessage({ id: "GUEST-LIST" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/agency-management"
        icon="user-tick"
        title={intl.formatMessage({ id: "AGENCY-MANAGEMENT" })}
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/agency-management/list"
          title={intl.formatMessage({ id: "AGENCY-LIST" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/agency-management/revenue"
          title={intl.formatMessage({ id: "REVENUE" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/agency-management/withdraw"
          title={intl.formatMessage({ id: "WITHDRAW" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItemWithSub
        to="/admin-management"
        title={intl.formatMessage({ id: "ADMIN-MANAGEMENT" })}
        fontIcon="bi-chat-left"
        icon="user-edit"
      >
        <SidebarMenuItem
          to="/admin-management/admin"
          title={intl.formatMessage({ id: "ADMIN" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/admin-management/role"
          title={intl.formatMessage({ id: "ROLES" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItemWithSub
        to="/payment-management"
        title={intl.formatMessage({ id: "PAYMENT-MANAGEMENT" })}
        fontIcon="bi-chat-left"
        icon="bill"
      >
        <SidebarMenuItem
          to="/content-sale-management/list"
          hasBullet={true}
          title={intl.formatMessage({ id: "CONTENT-SALE-HISTORY" })}
          fontIcon="bi-layers"
        />

        <SidebarMenuItem
          to="/payment/list"
          title={intl.formatMessage({ id: "IN-APP-PAYMENT-HISTORY" })}
          fontIcon="bi-layers"
          hasBullet={true}
        />

        <SidebarMenuItem
          to="/purchase-management/list"
          hasBullet={true}
          title={intl.formatMessage({ id: "NOW-USAGE-HISTORY" })}
          fontIcon="bi-layers"
        />
        <SidebarMenuItem
          to="/product-management/list"
          title={intl.formatMessage({ id: "IN-APP-PAYMENT-PRODUCT" })}
          fontIcon="bi-layers"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/content-management"
        title={intl.formatMessage({ id: "CONTENT-MANAGEMENT" })}
        fontIcon="bi-chat-left"
        icon="note-2"
      >
        <SidebarMenuItemWithSub
          to="/category-management/list"
          icon="map"
          title={intl.formatMessage({ id: "PLACE-MANAGEMENT" })}
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="/place-management/list"
            title={intl.formatMessage({ id: "PLACE-LIST" })}
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/place-management/category"
            title={intl.formatMessage({ id: "PLACE-CATEGORY-MANAGEMENT" })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to="/keyword"
          icon="text"
          title={intl.formatMessage({ id: "KEYWORD-MANAGEMENT" })}
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="/keyword/list"
            title={intl.formatMessage({
              id: "USER-KEYWORD-LIST",
            })}
            // hasBullet={true}
          />
          <SidebarMenuItem
            to="/keyword/group"
            title={intl.formatMessage({
              id: "USER-KEYWORD-GROUP",
            })}
            //  hasBullet={true}
          />

          <SidebarMenuItemWithSub
            to="/category-management/list"
            // icon="map"
            title={intl.formatMessage({ id: "KEYWORD-NOW-MANAGEMENT" })}
            fontIcon="bi-layers"
          >
            <SidebarMenuItem
              to="/explore-setting/male"
              title={intl.formatMessage({
                id: "KEYWORD_FOR_MALE",
              })}
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/explore-setting/female"
              title={intl.formatMessage({
                id: "KEYWORD_FOR_FEMALE",
              })}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to="/category-management/list"
            //icon="map"
            title={intl.formatMessage({ id: "RECOMMENDED-KEYWORD-MANAGEMENT" })}
            fontIcon="bi-layers"
          >
            <SidebarMenuItem
              to="/explore-setting/suggest-male"
              title={intl.formatMessage({
                id: "RECOMMENDED-KEYWORD-FOR-MALE",
              })}
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/explore-setting/suggest-female"
              title={intl.formatMessage({
                id: "RECOMMENDED-KEYWORD-FOR-FEMALE",
              })}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/report"
        icon="book"
        title={intl.formatMessage({ id: "CUSTOMER-INQUIRY-MANAGEMENT" })}
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/report/list"
          title={intl.formatMessage({
            id: "REPORT-LIST",
          })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/report/report-reason"
          title={intl.formatMessage({
            id: "REPORT-REASON",
          })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItemWithSub
        to="/faq"
        icon="question"
        title={intl.formatMessage({ id: "FAQ-MANAGEMENT" })}
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/faq/list"
          title={intl.formatMessage({
            id: "FAQ",
          })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/faq/group"
          title={intl.formatMessage({
            id: "GROUP",
          })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItemWithSub
        to="/others"
        icon="folder-added"
        title={intl.formatMessage({ id: "OTHERS" })}
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/others/general-setting"
          title={intl.formatMessage({
            id: "SETTING",
          })}
          hasBullet={true}
        />
        {/*   <SidebarMenuItem
          to="/report/system-noti"
          title={intl.formatMessage({
            id: "SYSTEM-NOTI",
          })}
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>

      {/*   <SidebarMenuItemWithSub
        to="/reservation"
        icon="test-tubes"
        title={intl.formatMessage({ id: "RESERVATION" })}
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/reservation/summary"
          title={intl.formatMessage({
            id: "SUMMARY",
          })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/reservation/users"
          title={intl.formatMessage({
            id: "USER",
          })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/reservation/ab-testing"
          title={intl.formatMessage({
            id: "MENU.EXPERIMENT_SEGMENT",
          })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      
    */}
    </>
  );
};

export { SidebarMenuMain };
