// @ts-nocheck
import { CircularProgress } from "@mui/material";
import { KTIcon, toAbsoluteUrlENV } from "_metronic/helpers";
import { useLang } from "_metronic/i18n/Metronici18n";
import { Popconfirm, notification, Switch } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import moment from "moment";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CreateAppModal } from "../components/edit-product";
import { delete_product, edit_category } from "../core/_requests";

const usersColumns: any = (
  hasPermissionDelete = true,
  hasPermissionUpdate = true
) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        useQueryRequest={useQueryRequest}
        isCenter={false}
      />
    ),
    id: "id",
    accessor: "id",
    expand: true,
    Cell: ({ ...props }) => {
      const id = props?.row?.original.id;
      const isChild = props?.row?.original?.parentId ? true : false;
      const levelPadding =
        !isChild || !props?.row?.original?.path
          ? 0
          : props?.row?.original?.path.split(".").length - 1;

      const marginLeft = levelPadding * 20 + "px";

      return (
        <div
          className={`cursor-pointer`}
          style={{
            width: 30,
            marginLeft,
          }}
        >
          {id}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ICON"
        useQueryRequest={useQueryRequest}
        className="min-w-100px text-center"
      />
    ),
    id: "icon",
    accessor: "icon",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;

      return row?.icon ? (
        <div className="mx-auto min-w-100px d-flex justify-content-center">
          <img
            src={toAbsoluteUrlENV(row?.icon)}
            alt="icon"
            style={{
              width: 30,
              height: "100%",
              objectFit: "cover",
              margin: "auto 0",
            }}
          />
        </div>
      ) : (
        <div className="min-w-100px"></div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NAME"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "name",
    accessor: "name",
    Cell: ({ ...props }) => {
      const lang = useLang();

      const row = props?.row?.original;
      if (lang == "ko")
        return (
          <div className="w-125px">
            <div>{row.name?.vi}</div>
            <div>{row.name?.ko}</div>
          </div>
        );
      else
        return (
          <div className="w-125px">
            <div>{row.name?.vi}</div>
            <div>{row.name?.en}</div>
          </div>
        );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="STATUS"
        className="min-w-125px"
        isNoSort={true}
        isCenter={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "isActive",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const [status, setStatus] = useState(row.isActive == "1");

      return (
        <Switch
          size="small"
          onChange={async (value) => {
            const body = {
              ...row,
              isActive: value ? "1" : "0",
            };
            const res = await edit_category(row?.id, body);
            if (res?.status == 200) setStatus(value);
          }}
          checked={status}
        />
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-125px"
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.createdAt;

      let day_kr = moment.utc(date).tz("Asia/Seoul").format("YYYY.MM.DD");
      let time_kr = moment.utc(date).tz("Asia/Seoul").format("HH:mm:ss");

      let day_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("YYYY.MM.DD");
      let time_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("HH:mm:ss");
      return (
        <div className="d-flex flex-column">
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              KR: {day_kr}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_kr}
            </div>
          </div>
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              VN: {day_vn}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_vn}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => (
      <div className="d-flex text-end" style={{ gap: 5 }}>
        <AddProductModal row={props?.row?.original} />
        {hasPermissionUpdate && <EditProductModal row={props?.row?.original} />}
        {hasPermissionDelete && <DeleteComponent row={props?.row?.original} />}
      </div>
    ),
  },
];

function EditProductModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="notepad-edit" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}

function AddProductModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="plus" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          parentId={row.id}
        />
      )}
    </>
  );
}

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const intl = useIntl();

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const res = await delete_product(row.id);
    setIsLoadingDelete(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE-SUCCESS" }),
      });
      refetch();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "PRODUCT-CONFIRM-DELETE" })}
        onConfirm={handleDelete}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          {isLoadingDelete ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon
              iconName="trash"
              className="fs-2 color-primary fill-primary"
            />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

export { usersColumns };
