// @ts-nocheck
import { CircularProgress } from "@mui/material";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { Popconfirm, Tag, Tooltip, notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import UserCellPoint from "app/_components/point/UserCellPoint";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { ACCOUNT_TYPE_UPDATE } from "app/constants";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { delete_admin } from "../core/_requests";
import dayjs from "dayjs";
import { IcNow } from "_metronic/assets/svgs/Now";
import male_icon from "_metronic/assets/svgs/male-icon.png";
import female_icon from "_metronic/assets/svgs/female-icon.png";
import moment from "moment";
import { GENDER } from "app/_modules/user-management/pages/profile/ProfileHeader";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import { useLang } from "_metronic/i18n/Metronici18n";
import { randomAntdColor } from "app/utils";
import { CreateAppModal } from "../components/edit-product";

export const PLATFORM = {
  RIGHT_NOW: "Rightnow HCM",
};

const usersColumns: any = (hasPermissionDelete: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        useQueryRequest={useQueryRequest}
        className="min-w-50px"
      />
    ),
    id: "id",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{row.id}</div>;
    },
  },
  /*  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        useQueryRequest={useQueryRequest}
        className="min-w-50px"
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{PLATFORM?.[row.platform]}</div>;
    },
  }, */

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NICKNAME"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "nickname",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TYPE"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
        isCenter={true}
      />
    ),
    id: "typeUser",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props?.row?.original;
      return intl.formatMessage({ id: row?.typeUser });
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="GENDER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "gender",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();

      const row = props?.row?.original;
      if (row?.gender == GENDER.MALE.toUpperCase())
        return <img src={male_icon} style={{ width: 16, height: 16 }} />;
      if (row?.gender == GENDER.FEMALE.toUpperCase())
        return <img src={female_icon} style={{ width: 16, height: 16 }} />;
      return (
        <div>
          {row?.gender
            ? intl.formatMessage({
                id: row?.gender,
              })
            : "-"}
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="OPERATOR"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
        isCenter={true}
      />
    ),
    id: "operator",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props?.row?.original;
      return row?.operator?.username || "-";
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NOW"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "balance",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <div
          className="d-flex align-items-center justify-content-center gap-1 px-4 py-1 border"
          style={{ borderRadius: "18px" }}
        >
          <IcNow className="h-15px" />
          <div className="text-gray-800 dw-bold d-block fs-7">
            {row?.wallet?.balance || 0}
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ROLE"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "role",
    accessor: "role",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props?.row?.original;
      return intl.formatMessage({ id: row?.role });
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="KEYWORD"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "keywords",
    accessor: "keywords",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const lang = useLang();
      const row = props?.row?.original;

      return (
        <div className="w-400px d-flex flex-wrap justify-content-center">
          {row?.keywords?.map((keyword, index) => {
            if (lang == "ko")
              return (
                <div className="mr-2">
                  <Tag
                    style={{ marginTop: 10 }}
                    bordered={false}
                    color={randomAntdColor(index)}
                    key={keyword}
                  >
                    {keyword?.value?.ko}
                  </Tag>
                </div>
              );
            else
              return (
                <div className="mr-2">
                  <Tag
                    style={{ marginTop: 10 }}
                    bordered={false}
                    color={randomAntdColor(index)}
                    key={keyword}
                  >
                    {keyword?.value?.en}
                  </Tag>
                </div>
              );
          })}
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.createdAt;

      let day_kr = moment.utc(date).tz("Asia/Seoul").format("YYYY.MM.DD");
      let time_kr = moment.utc(date).tz("Asia/Seoul").format("HH:mm:ss");

      let day_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("YYYY.MM.DD");
      let time_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("HH:mm:ss");
      return (
        <div className="d-flex flex-column">
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              KR: {day_kr}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_kr}
            </div>
          </div>
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              VN: {day_vn}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_vn}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const location = useLocation();
      return (
        <div className="d-flex" style={{ gap: 5 }}>
          <EditProductModal row={props?.row?.original} />

          {hasPermissionDelete && (
            <DeleteComponent row={props?.row?.original} />
          )}

          <Tooltip placement="top" title="User detail">
            <Link
              to={`${location.pathname}/detail/${props.row.original.id}`}
              className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
            >
              <KTIcon iconName="arrow-right" className="fs-2" />
            </Link>
          </Tooltip>
        </div>
      );
    },
  },
];

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

  const handleDeleteUser = async () => {
    setIsLoadingDeleteUser(true);
    const res = await delete_admin(row.id);
    setIsLoadingDeleteUser(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE.USER-SUCCESS" }),
      });
      refetch();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "USER.DELETE.CONFIRM" })}
        onConfirm={handleDeleteUser}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          {isLoadingDeleteUser ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon iconName="trash" className="fs-2" />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

function EditProductModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="notepad-edit" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}

export { usersColumns };
