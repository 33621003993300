// @ts-nocheck
import { KTIcon, toAbsoluteUrlENV } from "_metronic/helpers";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import clsx from "clsx";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { CreateAppModal } from "../components/edit-product";
import { ChangePasswordForm } from "../components/change-password-form";
import "moment-timezone";
import { IcNow } from "_metronic/assets/svgs/Now";

export const PLATFORM = {
  RIGHT_NOW: "Rightnow HCM",
};

const usersColumns: any = (hasPermissionDelete: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        useQueryRequest={useQueryRequest}
        className="min-w-50px"
      />
    ),
    id: "id",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{row.id}</div>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="FULLNAME"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "fullName",
    accessor: "fullName",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USERNAME"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "username",
    accessor: "username",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER-MANAGEMENT"
        useQueryRequest={useQueryRequest}
        className="min-w-50px"
      />
    ),
    id: "users",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const clone = [...row?.users].splice(0, 10);
      return (
        <div className="d-flex gap-5 flex-wrap align-items-center">
          {clone?.map((i: any) => (
            <div className="d-flex align-items-center">
              <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                {i?.pictures?.[0] ? (
                  <div className="symbol-label">
                    <div
                      className={`align-self-end my-auto h-100 w-100`}
                      style={{
                        backgroundImage: `url(${toAbsoluteUrlENV(
                          i?.pictures?.[0]
                        )})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                ) : (
                  <div className={clsx("symbol-label fs-3")}>
                    <img
                      alt="user"
                      src={"/media/avatars/blank.png"}
                      className="w-100"
                    />
                  </div>
                )}
              </div>
              <div className="text-dark fw-bold text-hover-primary fs-6">
                {i?.nickName}
              </div>
            </div>
          ))}
          {row?.users?.length > 10 && `+${row?.users?.length - 10} more`}
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TOTAL-ASSIGNED"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
        isCenter={true}
      />
    ),
    id: "total_assigned",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return row.users.length;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="INCOME"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
        isCenter={true}
      />
    ),
    id: "operatorEarned",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <div
          className="d-flex align-items-center justify-content-center gap-1 px-4 py-1 border"
          style={{ borderRadius: "18px" }}
        >
          <IcNow className="h-15px" />
          <div className="text-gray-800 dw-bold d-block fs-7">
            {row?.operatorEarned || 0}
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.createdAt;

      let day_kr = moment.utc(date).tz("Asia/Seoul").format("YYYY.MM.DD");
      let time_kr = moment.utc(date).tz("Asia/Seoul").format("HH:mm:ss");

      let day_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("YYYY.MM.DD");
      let time_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("HH:mm:ss");
      return (
        <div className="d-flex flex-column">
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              KR: {day_kr}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_kr}
            </div>
          </div>
          <div className="d-flex flex align-items-start gap-1">
            <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
              VN: {day_vn}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
              {time_vn}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const location = useLocation();
      return (
        <div className="d-flex" style={{ gap: 5 }}>
          <EditProductModal row={props?.row?.original} />
          <ChangePasswordModal row={props?.row?.original} />
          <Link
            to={`${location.pathname}/detail/${props.row.original.id}`}
            className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          >
            <KTIcon iconName="arrow-right" className="fs-2" />
          </Link>
        </div>
      );
    },
  },
];

function EditProductModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="notepad-edit" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}

function ChangePasswordModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="password-check" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <ChangePasswordForm
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}

export { usersColumns };
