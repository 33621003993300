// @ts-nocheck
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import moment from "moment";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import { useIntl } from "react-intl";
import { IcNow } from "_metronic/assets/svgs/Now";

const usersColumns: any = () => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="AGENCY"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "agency",
    accessor: "agency",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <div>{item.user.agency.name}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACCOUNT"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "nickname",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row.user} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NOW"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "now",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <div
          className="d-flex align-items-center justify-content-center gap-1 px-4 py-1 border"
          style={{ borderRadius: "18px" }}
        >
          <IcNow className="h-15px" />
          <div className="text-gray-800 dw-bold d-block fs-7">
            {row?.amount || 0}
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="VND"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    isCenterRow: true,
    id: "amountConverted",
    accessor: "amountConverted",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="BANK"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
      />
    ),
    id: "bank",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <>
          <div className="text-gray-800 dw-bold d-block fs-7">
            {row?.accountName} - {row?.accountNumber}
          </div>
          <div className="text-gray-600 dw-bold d-block fs-8">
            {row?.paymentGateway?.shortName} - {row?.paymentGateway?.name}
          </div>
        </>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="STATUS"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    isCenterRow: true,
    id: "status",
    accessor: "status",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "createdAt",
    accessor: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <CreateDate date={item.createdAt} />;
    },
  },
];

const CreateDate = ({ date }: any) => {
  let day_kr = moment.utc(date).tz("Asia/Seoul").format("YYYY.MM.DD");
  let time_kr = moment.utc(date).tz("Asia/Seoul").format("HH:mm:ss");

  let day_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("YYYY.MM.DD");
  let time_vn = moment.utc(date).tz("Asia/Ho_Chi_Minh").format("HH:mm:ss");
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex align-items-start gap-1">
        <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
          KR: {day_kr}
        </div>
        <div className="text-muted fw-semibold text-muted d-block fs-7">
          {time_kr}
        </div>
      </div>
      <div className="d-flex flex align-items-start gap-1">
        <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
          VN: {day_vn}
        </div>
        <div className="text-muted fw-semibold text-muted d-block fs-7">
          {time_vn}
        </div>
      </div>
    </div>
  );
};

export { usersColumns };
