/* eslint-disable jsx-a11y/anchor-is-valid */
import { CircularProgress, Box, Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Tabs, TabsProps, notification } from "antd";
import LanguageTabItem from "app/_components/LanguageTabItem";
import { removeFirstZero, validatePhone } from "app/utils";
import { toAbsoluteUrl } from "_metronic/helpers";
import CountryCode from "app/constants/countryCode.json";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";

import { detachPhoneCountryCode } from "app/utils/apiUntils";
import { createAgency, editAgency } from "../../core/_requests";
import SearchPhoneCountryCode from "./SearchPhoneCode";

const imageSrc = toAbsoluteUrl(
  "/media/icons/duotune/arrows/gray_arrowdown.svg"
);

dayjs.extend(customParseFormat);

const Step1 = ({ data, info, handleClose }: any) => {
  const isEdit = !!info;

  const { refetch } = useQueryResponse();
  const intl = useIntl();
  let dataInfo = { ...data, ...info };
  const isCheckHadCountryCode = () => {
    return dataInfo?.countryCode;
  };
  let phoneDetach = detachPhoneCountryCode(dataInfo?.phone);
  let filterCountryCode = isCheckHadCountryCode()
    ? CountryCode.find((item) => item.countryCodes[0] === dataInfo?.countryCode)
    : CountryCode.find((item) => item.countryCodes[0] === phoneDetach?.code);

  const [isShowPhoneCountry, setIsShowPhoneCountry] = useState<boolean>(false);
  const [isCountryCode, setIsCountryCode] = useState<any>(
    filterCountryCode || CountryCode[0]
  );
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const loginSchema = Yup.object().shape({
    code: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
    name: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
    password: Yup.string().when("isRequiredPassword", ([], schema) => {
      return !info
        ? schema
            .min(6, intl.formatMessage({ id: "MIN-SIX" }))
            .max(30, intl.formatMessage({ id: "MAX-THIRTY" }))
            .required(intl.formatMessage({ id: "REQUIRED_PASSWORD" }))
        : schema
            .notRequired()
            .min(6, intl.formatMessage({ id: "MIN-SIX" }))
            .max(30, intl.formatMessage({ id: "MAX-THIRTY" }));
    }),
    contactName: Yup.string().trim(),
    countryCode: Yup.string(),
    phoneNumber: Yup.number(),
    serviceFeePercentage: Yup.string()
      .min(0, intl.formatMessage({ id: "MIN-XERO" }))
      .max(100, intl.formatMessage({ id: "MAX-HUNDRED" })),
    commissionPercentage: Yup.string()
      .min(0, intl.formatMessage({ id: "MIN-XERO" }))
      .max(100, intl.formatMessage({ id: "MAX-HUNDRED" })),
  });

  const initialValues = {
    id: dataInfo?.id,
    code: dataInfo?.code,
    name: dataInfo?.name,
    contactName: dataInfo?.contactName || "",
    countryCode: dataInfo?.countryCode || "+84",
    phoneNumber: dataInfo?.phoneNumber || "",
    password: dataInfo?.password || "",
    serviceFeePercentage: Number(dataInfo?.serviceFeePercentage || 0),
    commissionPercentage: Number(dataInfo?.commissionPercentage || 0),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setIsLoadingCreate(true);
      try {
        let params = {
          code: values.code,
          name: values.name,
          ...(values.contactName && { contactName: values.contactName }),
          ...(values.countryCode && { countryCode: values.countryCode }),
          ...(values?.phoneNumber && {
            phoneNumber: removeFirstZero(values?.phoneNumber),
          }),
          ...(values.commissionPercentage >= 0 && {
            commissionPercentage: Number(values.commissionPercentage),
          }),
          ...(values.serviceFeePercentage >= 0 && {
            serviceFeePercentage: Number(values.serviceFeePercentage),
          }),
        } as any;
        if (values?.id) {
          params.id = values.id;
          if (values?.password) {
            params.password = values.password;
          }
        } else {
          params.password = values.password;
        }
        const res: any = values?.id
          ? await editAgency(params)
          : await createAgency(params);
        if (res.success === true) {
          notification.success({
            message: isEdit ? "Update successfully" : "Create successfully",
            duration: 3,
          });
          setIsLoadingCreate(false);
          refetch();
          handleClose();
        }
      } catch (error) {
        setIsLoadingCreate(false);
        notification.error({
          message: "error",
          duration: 3,
        });
      } finally {
        setIsLoadingCreate(false);
      }
    },
  });

  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-full">
          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "AGENCY_ID" })}
              </span>
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              placeholder={intl.formatMessage({ id: "TYPE_AGENCY_ID" })}
              {...formik.getFieldProps("code")}
              disabled={isEdit}
            />

            {formik.touched.code && formik.errors.code && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.code?.toString()}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "AGENCY_NAME" })}
              </span>
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              placeholder={intl.formatMessage({ id: "TYPE_AGENCY_NAME" })}
              {...formik.getFieldProps("name")}
            />

            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.name?.toString()}</span>
                </div>
              </div>
            )}
          </div>

          {!isEdit && (
            <div className="fv-row mb-10">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "PASSWORD",
                  })}
                </span>
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                placeholder={intl.formatMessage({ id: "TYPE_PASSWORD" })}
                type="password"
                autoComplete="new-password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {formik.errors.password?.toString()}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span>{intl.formatMessage({ id: "CONTACT-NAME" })}</span>
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              placeholder={intl.formatMessage({ id: "TYPE-CONTACT-NAME" })}
              {...formik.getFieldProps("contactName")}
            />

            {formik.touched.contactName && formik.errors.contactName && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">
                    {formik.errors.contactName?.toString()}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span>{intl.formatMessage({ id: "PHONE_NUMBER" })}</span>
            </label>
            <div className="d-flex d-flex-row align-items-center bg-gray-100 rounded">
              <div
                className="px-3 d-flex cursor-pointer gap-2 fw-SemiBold fs-6 text-gray-700"
                onClick={() => setIsShowPhoneCountry(!isShowPhoneCountry)}
              >
                {formik.values.countryCode}
                {/* <img src={imageSrc} alt="" /> */}
                {isShowPhoneCountry && (
                  <SearchPhoneCountryCode
                    onClose={() => setIsShowPhoneCountry(!isShowPhoneCountry)}
                    selectCountry={(item: any) => {
                      formik.setFieldValue("countryCode", item.countryCodes[0]);
                      setIsShowPhoneCountry(!isShowPhoneCountry);
                    }}
                  />
                )}
              </div>
              <input
                className="form-control form-control-solid"
                placeholder={intl.formatMessage({ id: "TYPE_PHONE" })}
                maxLength={13}
                autoComplete="new-phone"
                {...formik.getFieldProps("phoneNumber")}
              />
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">
                    {formik.errors.phoneNumber?.toString()}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span>{intl.formatMessage({ id: "COMMISSION-PERCENTAGE" })}</span>
            </label>
            <div className="d-flex d-flex-row align-items-center bg-gray-100 rounded">
              <input
                className="form-control form-control-solid"
                placeholder={intl.formatMessage({
                  id: "TYPE-COMMISSION-PERCENTAGE",
                })}
                {...formik.getFieldProps("commissionPercentage")}
              />
            </div>
            {formik.touched.commissionPercentage &&
              formik.errors.commissionPercentage && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {formik.errors.commissionPercentage?.toString()}
                    </span>
                  </div>
                </div>
              )}
          </div>

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span>{intl.formatMessage({ id: "SERVICE-PERCENTAGE" })}</span>
            </label>
            <div className="d-flex d-flex-row align-items-center bg-gray-100 rounded">
              <input
                className="form-control form-control-solid"
                placeholder={intl.formatMessage({
                  id: "TYPE-SERVICE-PERCENTAGE",
                })}
                {...formik.getFieldProps("serviceFeePercentage")}
              />
            </div>
            {formik.touched.serviceFeePercentage &&
              formik.errors.serviceFeePercentage && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {formik.errors.serviceFeePercentage?.toString()}
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-stack justify-content-end">
        <button
          disabled={formik.isSubmitting || !formik.isValid}
          className="btn btn-lg btn-primary"
          //data-kt-stepper-action="next"
          //onClick={handleSubmit}
          type="submit"
        >
          {isLoadingCreate ? (
            <CircularProgress size={16} />
          ) : (
            intl.formatMessage({ id: "SAVE" })
          )}
        </button>
      </div>
    </form>
  );
};

export { Step1 };
