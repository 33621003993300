import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QuestionsUsers } from "../core/_models";
import { IcNoData } from "_metronic/assets/svgs/NoData";

import { Collapse, Space } from "antd";

export default function ControlledAccordions({
  data,
}: {
  data: QuestionsUsers[];
}) {
  const [expanded, setExpanded] = React.useState<number | null>(null);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  return (
    <>
      {data?.length ? (
        <div className="w-100 mw-700px d-grid gap-3">
          {data?.map((item, index) => (
            <Collapse
              collapsible="header"
              //defaultActiveKey={[index]}
              items={[
                {
                  key: index,
                  label: (
                    <>
                      <div className="text-gray-800 text-hover-primary fw-bold me-2">
                        {item.content.value}
                      </div>
                    </>
                  ),
                  children: (
                    <div
                      style={{ paddingLeft: 24 }}
                      className="text-gray-800 fs-7 fw-normal pt-1"
                    >
                      {item.answer}
                    </div>
                  ),
                },
              ]}
            />
          ))}
        </div>
      ) : (
        <div className="w-100 text-center">
          <IcNoData height={100} />
        </div>
      )}
    </>
  );
}
