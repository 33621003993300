/* eslint-disable react-hooks/exhaustive-deps */
import { KTIcon, useDebounce } from "_metronic/helpers";
import { Input } from "antd";
import { useEffect, useState } from "react";

const ListSearchComponent = ({
  useQueryRequest,
  placeholder,
  defaultSearch,
  setWidth = 300,
  updateState,
  state,
  uiType,
}: any) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  // Effect for API call

  useEffect(
    () => {
      //console.log(state);
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ ...state, search: debouncedSearchTerm, page: 1 });
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  useEffect(() => {
    if (!state?.search) {
      setSearchTerm("");
    }
  }, [state?.search]);

  if (uiType == 2)
    return (
      <div className="card-title">
        {/* begin::Search */}
        <div className="d-flex align-items-center position-relative my-1">
          <KTIcon
            iconName="magnifier"
            className="fs-1 position-absolute ms-6"
          />
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-250px ps-14"
            placeholder={placeholder || "type name"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* end::Search */}
      </div>
    );

  return (
    <div className="card-title d-flex align-items-center">
      <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>

      {/* begin::Search */}
      <div className="d-flex position-relative align-items-center position-relative min-w-100 min-w-sm-250px">
        <KTIcon
          iconName="magnifier"
          className="fs-1 position-absolute ms-4 start-0 z-index-2"
        />
        <Input
          allowClear
          className="d-flex form-control bg- form-control-solid ps-14 min-w-100 min-w-sm-250px"
          placeholder={placeholder || "type name"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* <input
          data-kt-user-table-filter="search"
          className="form-control form-control-solid ps-14 min-w-100 min-w-sm-250px"
          placeholder={placeholder || "type username"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CloseCircleFilled
          twoToneColor="#eb2f96"
          className="position-absolute cursor-pointer end-0 me-2"
          rev={undefined}
        /> */}
      </div>
      {/* end::Search */}
    </div>
  );
};

export { ListSearchComponent };
