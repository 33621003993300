/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { CreateProduct } from "../core/_models";
import { Step1 } from "./steps/Step1";

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({ show, handleClose, info, parentId = null }: any) => {
  const intl = useIntl();

  const { refetch } = useQueryResponse();

  const [data, setData] = useState<CreateProduct | null>(null);
  const [hasError, setHasError] = useState(false);

  const handleOnCloseModal = () => {
    setHasError(false);
    setData(null);
    handleClose();
  };

  const submit = async () => {
    // setIsLoadingCreate(true);
    // try {
    //   const fortmat_data = {
    //     ...data,
    //     credit: Number(data?.credit) || 0,
    //   };
    //   if (info) {
    //     await edit_product(info.id, fortmat_data);
    //   } else {
    //     await create_product(fortmat_data);
    //   }
    //   notification.success({
    //     message: info?.id
    //       ? intl.formatMessage({
    //           id: "UPDATE-SUCCESS",
    //         })
    //       : intl.formatMessage({
    //           id: "CREATE-SUCCESS",
    //         }),
    //   });
    //   setData(null);
    //   handleClose();
    //   refetch();
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setIsLoadingCreate(false);
    // }
  };

  useEffect(() => {
    setData({ ...info });
  }, [info]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px admin-info-modal"
      show={show}
      onHide={handleOnCloseModal}
      backdrop={true}
      onBackdropClick={handleOnCloseModal}
    >
      <div className="modal-header">
        <h2>
          {info
            ? intl.formatMessage({ id: "EDIT" })
            : intl.formatMessage({ id: "CREATE" })}
        </h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleOnCloseModal}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div
        className="modal-body py-lg-10 px-lg-10"
        // style={{ minWidth: "750px" }}
      >
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          {/*begin::Content */}
          <div style={{ minWidth: "100%" }}>
            {/*begin::Form */}
            <Step1
              data={data}
              updateData={setData}
              hasError={hasError}
              info={info}
              refetch={refetch}
              handleClose={handleClose}
              parentId={parentId}
            />
            <form noValidate id="kt_modal_create_app_form">
              {/*begin::Actions */}
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    //onClick={prevStep}
                  >
                    <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                    Previous
                  </button>
                </div>
                <div>
                  {/* <button
                    type="button"
                    className="btn btn-lg btn-primary"
                    data-kt-stepper-action="next"
                    // disabled={
                    //   // !data?.platform ||
                    //   // !data?.name ||
                    //   // !data?.pic ||
                    //   // !data?.release_date ||
                    //   // !data?.USD ||
                    //   // !data?.KRW ||
                    //   // (!info && !data?.code)
                    // }
                    onClick={submit}
                  >
                    {isLoadingCreateAdmin ? (
                      <CircularProgress size={16} />
                    ) : (
                      intl.formatMessage({ id: "SAVE" })
                    )}
                  </button> */}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
