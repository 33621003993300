export const IcNow = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.0968 11.0866L12.0911 21.32C11.7382 21.8286 10.9079 21.6002 10.9079 20.9879L10.8976 15.1343C10.8976 14.4596 10.3268 13.92 9.621 13.9096L5.43836 13.8577C4.9298 13.8473 4.62882 13.318 4.90905 12.9132L11.9147 2.6798C12.2676 2.17124 13.0979 2.39958 13.0979 3.01192L13.1082 8.86554C13.1082 9.54016 13.6791 10.0798 14.3848 10.0902L18.5675 10.1421C19.0656 10.1421 19.3666 10.6818 19.0968 11.0866Z"
        fill="url(#paint0_linear_2216_21984)"
        stroke="url(#paint1_linear_2216_21984)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2216_21984"
          x1="5.16167"
          y1="2.3999"
          x2="23.2599"
          y2="16.3181"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.267361" stop-color="#A066FF" />
          <stop offset="1" stop-color="#8033FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2216_21984"
          x1="5.16167"
          y1="2.3999"
          x2="23.2599"
          y2="16.3181"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.267361" stop-color="#A066FF" />
          <stop offset="1" stop-color="#8033FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
