import axios, { AxiosResponse } from "axios";
import { UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/transactions/purchase-history`;

const get_purchase_history = (query: any): Promise<UsersQueryResponse> => {
  return (
    axios
      .get(`${GET_USERS_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.list;
        const pagination = d.data.meta;
        const articleType = d.data.data.articleType;

        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(
              pagination?.page,
              pagination?.total,
              pagination?.limit
            ),
            total: pagination?.total,
          },
          articleType: articleType,
        };
      })
  );
};

// const edit_purchase_history = (id: any, body: any) => {
//   return axios.put(`${GET_USERS_URL}/${id}`, body);
// };

export const getPaginationPosts = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  // if (!newquery.is_active) {
  //   newquery.is_active = false;
  // }
  const res = await axios.get(`${GET_USERS_URL}`, { params: newquery });
  const { data, count } = res.data;
  const pagination = {
    page: newquery.page,
    total: count,
    limit: newquery.limit,
  };

  return {
    data: data as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
  };
};

export {
  get_purchase_history,
  // edit_withdraw,
};
