import {
  KTCard,
  KTIcon,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { Table } from "app/_components/table/Table";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  getPaginationPosts,
  getStatisticContentSale,
  getUsersInOPerator,
} from "./core/_requests";
import { usersColumns } from "./table/_columns";
import { useAuth } from "app/_modules/auth";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { PageTitle } from "_metronic/layout/core";
import { getAllOperators } from "../user-management/pages/operator/core/_requests";
import axios from "axios";

const PurchaseHistoryList = () => {
  const users = useQueryResponseData();
  const { state, updateState } = useQueryRequest();
  const { query } = useQueryResponse();

  const data: any = useMemo(() => users, [users]);
  const [operatorDataOptions, setOperatorDataOptions] = useState<any>([]);
  const [userDataOptions, setUserDataOptions] = useState<any>([]);
  const [statisticData, setStatisticData] = useState<any>();

  const intl = useIntl();

  const res: any = useQueryResponse();
  const pag = useQueryResponsePagination();

  const { allPermission, myPermission } = useAuth();

  let cardList = [
    {
      title: intl.formatMessage({
        id: "TOTAL-TRANSACTION",
      }),
      value: statisticData?.totalTransaction,
    },
    {
      title: intl.formatMessage({
        id: "TOTAL-NOW",
      }),
      value: statisticData?.totalNow,
    },
    {
      title: intl.formatMessage({
        id: "TOTAL-AMOUNT",
      }),
      value: statisticData?.totalAmount?.[0],
    },
    {
      title: intl.formatMessage({
        id: "MIN-NOW",
      }),
      value: statisticData?.minNow,
    },
    {
      title: intl.formatMessage({
        id: "MAX-NOW",
      }),
      value: statisticData?.maxNow,
    },
  ];

  let permissionColumns = useMemo(
    () => usersColumns(true),
    [allPermission, myPermission]
  );

  async function getAllOps() {
    const res = await getAllOperators();

    if (res?.status) {
      const options = res?.data?.data?.map((i: any) => {
        return {
          value: i?.id?.toString(),
          label: i?.username,
        };
      });

      setOperatorDataOptions(options);
    }
  }

  useEffect(() => {
    getAllOps();
  }, []);

  async function getAllUsersOps(operatorIds: any) {
    const resData = await getUsersInOPerator(operatorIds);

    if (resData?.length) {
      const options = resData?.map((i: any) => {
        return {
          value: i?.id?.toString(),
          label: i?.nickName,
        };
      });

      setUserDataOptions(options);
    }
  }

  useEffect(() => {
    getAllUsersOps(state?.operatorIds);
  }, [state?.operatorIds]);

  async function getStatistic(query: any) {
    const res = await getStatisticContentSale(query);
    setStatisticData(res);
  }

  useEffect(() => {
    if (!state.start_date && !state.end_date)
      updateState({
        ...state,
        start_date: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        end_date: new Date(),
      });
  }, [state]);

  useEffect(() => {
    getStatistic(query);
  }, [query]);

  const headerOptions = [
    ...OPTION_KEYS_HEADER.CONTENT_SALE_HISTORY,
    {
      id: "MULTI-OPTIONS",
      title: "OPERATOR",
      type: "operatorIds",
      dataOptions: operatorDataOptions,
    },

    {
      id: "MULTI-OPTIONS",
      title: "USER",
      type: "userIds",
      dataOptions: userDataOptions,
    },
  ];

  return (
    <div>
      <PageTitle>
        {intl.formatMessage({ id: "CONTENT-SALE-HISTORY" })}
      </PageTitle>
      {!data ? null : <UsersListHeader optionsHeader={headerOptions} />}
      <KTCard>
        <div className="d-flex align-items-center justify-content-between">
          <TableSummary cardList={cardList} />
        </div>
        <Table
          defineColumns={permissionColumns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const UsersListWrapper = () => (
  <TableWrapper module="post" getPanigation={getPaginationPosts}>
    <PurchaseHistoryList />
  </TableWrapper>
);

export default UsersListWrapper;
