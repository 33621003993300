import { PageTitle } from "_metronic/layout/core";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import ListUsers from "./ListUsers";
import TransactionHistory from "./TransactionHistory";

export default function OperatorDetail() {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <PageTitle>
        <div
          id="kt_page_title"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="symbol symbol-circle symbol-5px overflow-hidden me-3"
              onClick={handleBackClick}
            >
              <div className="symbol-label" style={{ width: 30, height: 30 }}>
                <button className="btn btn-lg text-center">
                  <span>
                    <i className="fa fa-arrow-left fa-3x"></i>
                  </span>
                </button>
              </div>
            </div>
            <div className={clsx("menu-title fw-bold fs-5")}>
              {intl.formatMessage({
                id: "DETAIL",
              })}
            </div>
          </div>
        </div>
      </PageTitle>
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
          <div className="card">
            <ListUsers />
          </div>
        </div>

        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          <div className="card">
            <TransactionHistory />
          </div>
        </div>
      </div>
    </>
  );
}
