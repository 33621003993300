import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { usersColumns } from "./table/_columns";

import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useAuth } from "app/_modules/auth";
// import { getPanigationUsers } from "./core/_requests";
import { getPanigationAgency, getStatisticAgencyList } from "./core/_requests";
import { UsersListToolbar } from "./core/header/UserListToolbar";
import { MenuComponent } from "_metronic/assets/ts/components";

interface Props {
  className?: string;
  data?: any;
  pagination?: any;
  type?: string;
  columns?: any;
}
export const Registrations = ({ data, pagination, type, columns }: Props) => {
  const users: any = useQueryResponseData();
  const { query } = useQueryResponse();

  const dataUser = useMemo(() => users, [users]);
  const [statisticData, setStatisticData] = useState<any>();

  const { allPermission, myPermission } = useAuth();

  const intl = useIntl();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  async function getStatistic(query: any) {
    const res = await getStatisticAgencyList(query);
    setStatisticData(res);
  }

  useEffect(() => {
    getStatistic(query);
  }, [query]);

  let cardList = [
    {
      title: intl.formatMessage({
        id: "TOTAL",
      }),
      value: pagination?.total || pag?.total,
    },
    {
      title: intl.formatMessage({
        id: "STATUS.ACTIVE",
      }),
      value: statisticData?.active || 0,
    },
    {
      title: intl.formatMessage({
        id: "STATUS.INACTIVE",
      }),
      value: statisticData?.inActive || 0,
    },
  ];

  cardList.sort((a, b) => {
    return b.value - a.value;
  });

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_USER,
    allPermission,
    myPermission
  );

  let permissionColumns = useMemo(
    () => usersColumns(hasPermissionDelete),
    [allPermission, myPermission]
  );

  return (
    <>
      {data ? null : (
        <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.AGENCY} />
      )}
      <div>
        <KTCard>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <TableSummary cardList={cardList} />
            <div className="card-toolbar p-8">
              <UsersListToolbar />
            </div>
          </div>
          <Table
            defineColumns={permissionColumns}
            data={data || dataUser}
            useQueryResponsePagination={useQueryResponsePagination}
            useQueryResponseLoading={useQueryResponseLoading}
            useQueryRequest={useQueryRequest}
            pagination={pagination}
            // className="frenzy"
          />
        </KTCard>
      </div>
    </>
  );
};

const UsersListWrapper = () => (
  <TableWrapper getPanigation={getPanigationAgency}>
    <Registrations />
  </TableWrapper>
);

export default UsersListWrapper;
