import { useEffect, useMemo, useRef, useState } from "react";
import { useTable, ColumnInstance, Row, useBlockLayout } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { UsersListLoading } from "./loading/UsersListLoading";
import { Pagination } from "./pagination/Pagination";
import { KTCardBody } from "_metronic/helpers";
import { useIntl } from "react-intl";
import Draggable from "./Draggable";
import { useSticky } from "react-table-sticky";
import React from "react";
import { SubTableComponent } from "./SubTableComponent";

const Table = ({
  className,
  defineColumns,
  data,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryRequest,
  isHiddenHeader = false,
  isHiddenPagination = false,
}: any) => {
  const isLoading = useQueryResponseLoading();
  const columns = useMemo(() => defineColumns, [defineColumns]);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);
  const tableRef = useRef<any>(null);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      // useBlockLayout,
      useSticky
    );

  const [expandedRows, setExpandedRows] = useState([]);

  // Update handleRowClick to toggle the visibility of child rows
  const handleRowClick = (row: any) => {
    if (row.original?.children && row.original?.children?.length > 0) {
      setExpandedRows((prevExpandedRows: any) =>
        prevExpandedRows.includes(row.original.id)
          ? prevExpandedRows.filter((id: any) => id !== row.original.id)
          : [...prevExpandedRows, row.original.id]
      );
    }
  };

  const intl = useIntl();

  const handleScroll = () => {
    const table = tableRef.current;
    if (table) {
      const { scrollWidth, scrollLeft, clientWidth } = table;
      setIsScrollAtEnd(scrollWidth - scrollLeft === clientWidth);
    }
  };

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [data]);

  let headerComponent = useMemo(() => {
    return headers.map((column: ColumnInstance<any>) => (
      <CustomHeaderColumn key={column.id} column={column} />
    ));
  }, [headers]);

  return (
    <KTCardBody className={`${isHiddenHeader ? "p-0" : "py-4"}  `}>
      <div className="table-responsive" ref={tableRef}>
        <Draggable isHiddenHeader={isHiddenHeader}>
          <table
            id="kt_table_users"
            className={`table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer ${
              !isScrollAtEnd && className
            }`}
            {...getTableProps()}
          >
            <thead>
              <tr
                className={`text-start text-muted fw-bolder fs-7 text-uppercase gs-0 ${
                  isHiddenHeader ? "d-none" : ""
                }`}
              >
                {headerComponent}
                {/* {headers.map((column: ColumnInstance<any>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))} */}
              </tr>
            </thead>

            {isLoading && <UsersListLoading colSpan={headers.length} />}
            {!isLoading && (
              <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
                {rows.length > 0 &&
                  rows.map((row: any, i) => {
                    prepareRow(row);
                    return (
                      <>
                        <CustomRow
                          onClick={() => handleRowClick(row)}
                          row={row}
                          key={`row-${i}-${row.id}`}
                          expandedRows={expandedRows}
                        />
                        {expandedRows.includes(row.original.id as never) &&
                        row.original?.children &&
                        row.original?.children?.length > 0 ? (
                          <>
                            <tr>
                              <td colSpan={columns.length} className="header-0">
                                <SubTableComponent
                                  className="w-100"
                                  isHiddenHeader={true}
                                  data={row.original.children}
                                  defineColumns={defineColumns}
                                  useQueryResponsePagination={
                                    useQueryResponsePagination
                                  }
                                  useQueryResponseLoading={
                                    useQueryResponseLoading
                                  }
                                  useQueryRequest={useQueryRequest}
                                  isHiddenPagination={true}
                                />
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </>
                    );
                  })}
              </tbody>
            )}
          </table>
        </Draggable>

        {!isLoading && rows.length == 0 && (
          <div className="d-flex text-center w-100 align-content-center justify-content-center text-gray-600 fw-bold table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer pt-8">
            {intl.formatMessage({ id: "TABLE.NOT-FOUND-DATA" })}
          </div>
        )}
      </div>
      {useQueryResponsePagination && !isHiddenPagination && (
        <Pagination
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      )}
    </KTCardBody>
  );
};

export { Table };
