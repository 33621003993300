/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select, notification } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import {
  create_operator,
  edit_operator,
  getAllOperator,
} from "../../core/_requests";
import InputComponent from "./InputComponent";

const Step1 = ({ refetch, handleClose, info }: any) => {
  const isEdit = !!info;
  const intl = useIntl();

  const data = info;

  const [allUsers, setAllUsers] = useState<any>([]);
  useEffect(() => {
    getAllOperators();
  }, []);

  async function getAllOperators() {
    const res = await getAllOperator();
    if (res?.status == 200) {
      const data = res?.data?.data;
      const formatData = data?.map((d: any) => {
        return {
          value: d?.id,
          label: d?.nickName,
          renderLabel: (
            <>
              <div className="d-flex align-items-center text-hover-primary fw-medium">
                {d.username}
              </div>
            </>
          ),
        };
      });

      setAllUsers(formatData);
    }
  }

  const createEditProductSchema = Yup.object().shape({
    fullName: Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),
    username: Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),

    password: isEdit
      ? Yup.string().nullable().notRequired()
      : Yup.string().required(intl.formatMessage({ id: "REQUIRED" })),

    users: Yup.array().required(intl.formatMessage({ id: "REQUIRED" })),
  });

  const initialValues = {
    fullName: data?.fullName || "",
    username: data?.username || "",
    password: data?.password || "",
    users: data?.operators?.map((i: any) => i?.id?.toString()) || [],
  };

  const formik: any = useFormik({
    enableReinitialize: isEdit,
    initialValues,
    validationSchema: createEditProductSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const fortmat_data = {
          fullName: values?.fullName,
          password: values?.password,
          username: values?.username,
          operatorIds: values?.users?.map((i: any) => Number(i)) || [],
        };
        let res;
        if (info) {
          delete fortmat_data.password;
          res = await edit_operator(info.id, fortmat_data);
        } else {
          res = await create_operator(fortmat_data);
        }
        if (res?.status == 200 || res?.status == 201) {
          notification.success({
            message: info?.id
              ? intl.formatMessage({
                  id: "UPDATE-SUCCESS",
                })
              : intl.formatMessage({
                  id: "CREATE-SUCCESS",
                }),
          });
          handleClose();
          refetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  console.log(formik.values?.users);

  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm min-w-400px"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          <div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "FULLNAME" })}
                fieldKey="fullName"
              />
            </div>
          </div>
          {/* KO */}
          <div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "USERNAME" })}
                fieldKey="username"
                disabled={isEdit}
              />
            </div>
          </div>

          {/* EN */}
          {!isEdit && (
            <div>
              <div className="row">
                <InputComponent
                  formik={formik}
                  label={intl.formatMessage({
                    id: "PASSWORD",
                  })}
                  fieldKey="password"
                  inputType="password"
                  isRequired={isEdit ? false : true}
                />
              </div>
            </div>
          )}

          {/* VI */}

          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "OPERATORS" })}
              </span>
            </label>
            <Select
              mode="multiple"
              showArrow
              placeholder={intl.formatMessage({
                id: "SELECT-OPERATORS",
              })}
              value={formik.values?.users}
              filterOption={(inputValue: any, option: any) => {
                const thisItem = allUsers.find(
                  (i: any) => i?.value == option?.value
                );

                return thisItem?.label
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());

                // return option.children
                //   ?.toString()
                //   ?.concat(option?.value?.toString())
                //   ?.toLowerCase()
                //   ?.includes(inputValue?.toLowerCase());
              }}
              onChange={(value) => {
                console.log(value);
                formik.setFieldValue("users", value);
              }}
              className="w-100 operator-female-select"
            >
              {allUsers?.map((option: any) => (
                <Select.Option key={option.value}>
                  {option.renderLabel}
                </Select.Option>
              ))}
            </Select>
            {formik.errors.users && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  {formik.errors.users}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-stack justify-content-end">
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          disabled={/* formik.isSubmitting || */ !formik.isValid}
        >
          {intl.formatMessage({ id: "SAVE" })}
        </button>
      </div>{" "}
    </form>
  );
};

export { Step1 };
