// @ts-nocheck
import { KTIcon } from "_metronic/helpers";
import Column from "antd/es/table/Column";
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";

type Props = {
  row: Row<any>;
  className?: string;
  onClick?: (row: any) => void;
  expandedRows?: string[];
};
const CustomRow: FC<Props> = ({
  row,
  className = "",
  onClick,
  expandedRows,
}) => {
  return (
    <tr onClick={onClick} {...row.getRowProps()} className={`${className}`}>
      {row?.cells?.map((cell, index) => {
        return (
          <td
            key={index}
            {...cell.getCellProps()}
            // className={clsx({
            //   "text-end min-w-100px": cell.column.id === "actions",
            // })}
          >
            <div className={`${cell?.column?.expand ? "d-flex" : ""}`}>
              {cell?.column?.isCenterRow ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {cell.render("Cell")}
                </div>
              ) : (
                <>{cell.render("Cell")}</>
              )}
              <span>
                {row?.original?.children?.length && cell?.column?.expand ? (
                  expandedRows?.includes(row?.original?.id) ? (
                    <KTIcon iconName="up" className="fs-2" />
                  ) : (
                    <KTIcon iconName="down" className="fs-2" />
                  )
                ) : null}
              </span>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export { CustomRow };
